// Hero Paragraph styling


$hero-height-high: rem-calc(330);
$hero-height-mobile: 40vh;
$hero-bottom-height-mobile: 12.5vh;
$hero-bottom-height: 20vh;
$hero-bottom-left-width-mobile: 18vw;
$hero-bottom-left-width: 21.5vw;
$hero-bottom-blue-line-height: 80px;
$hero-bottom-blue-line-height-mobile: 45px;
$hero-secondary-img-bottom: 120px;

.paragraph-hero {
  position: relative;
  color: $black;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.44), rgba(0, 0, 0, 0));

  @media print {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &__img {
    @include breakpoint(large) {

      width: 100%;
      height: 100%;
    }

    .image {
      display: block;
      width: 100%;

      @include breakpoint(large) {

        width: 100%;
        height: 100%;
      }
    }

    img {
      display: block;
      width: 100%;
      object-fit: cover;



      @include breakpoint(large) {
        width: 100%;
        height: 100%;
      }

      @include breakpoint(small only) {
        min-height: $hero-height-mobile;
      }
    }

  }

  &__content {

    @include custom-xy-grid-container();
    padding: 0;
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    @include breakpoint(medium down) {
      margin-left: 0;
      margin-right: 0;
      width: auto;
    }

    @include breakpoint(medium) {
      max-width: 63.75rem;
    }

    @include breakpoint(large down) {
      min-height: rem-calc(380);

    }

    @media (min-width: 1100px) {
      padding-left: 0;
      padding-right: 0;
    }

    @include breakpoint(xlarge) {
      margin-left: 0;
      margin-right: auto;
    }

    @include breakpoint(small only) {
      /* position: relative; */
      display: flex;
      justify-content: flex-end;
      margin: 0;
    }

    &.half {

      @include breakpoint(medium up) {
        max-width: 63.75rem;
      }

      @include breakpoint(xlarge) {
        max-width: 100rem;
      }

      .content-inner {
        display: inline-table;
        max-width: 50%;
        top: 50%;
        transform: translateY(-50%);

        @include breakpoint(small only) {
          position: relative;
          top: 0;
          transform: none;
          max-width: 100%;
        }

        .cta-link {
          margin-top: $space-double;
          margin-left: 0;
        }
      }
    }

    .content-inner {

      >* {
        &:last-child {
          margin-bottom: 0;
        }
      }

      position: absolute;
      bottom: $space-quadruple;
      display: flex;
      align-items: flex-end;

      @include breakpoint(medium) {
        max-width: 100%;
        padding: $space-double $space-quadruple;
      }

      @include breakpoint(medium down) {
        padding: $space-double;
      }

      @include breakpoint(small only) {
        display: block;
      }

      &.opaque-box {
        background-color: rgba(255, 255, 255, 0.8);
        color: $black;
      }

    }
  }

  &__heading {
    margin-bottom: $space;
    font-size: rem-calc(28);

    @include breakpoint(medium) {
      font-size: rem-calc(34);
    }

    @include breakpoint(large) {
      font-size: rem-calc(40);
      margin-bottom: $space-double;
    }
  }

  &__teaser {
    margin-bottom: 0;
    font-size: rem-calc(16);

    @include breakpoint(medium) {
      font-size: rem-calc(18);
    }

    @include breakpoint(large) {
      font-size: rem-calc(20);
    }

    ul {
      margin: 0 0 $space-double 0;
      list-style: none;

      li {
        padding-left: 3.4*$space;
        line-height: 1.5;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30.4' height='26' version='1.1' id='svg841'%3E%3Cstyle id='style833'/%3E%3Cdefs id='defs845'%3E%3Cfilter id='filter854' x='-.3' width='1.6' y='-.5' height='2' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur stdDeviation='3' id='feGaussianBlur856'/%3E%3C/filter%3E%3C/defs%3E%3Cpath id='path844' d='M11.7 19.5l13-13m-19 7l6 6' fill='none' fill-rule='evenodd' stroke='%23000' stroke-width='3' stroke-linecap='round' stroke-opacity='.5' filter='url(%23filter854)'/%3E%3Cpath id='Line-2' d='M11.7 19.5l13-13m-19 7l6 6' fill='none' fill-rule='evenodd' stroke='%23fff' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 2px center;
      }
    }
  }


  .cta-link {
    @include button($bg:$white, $color:$primary-color, $hoverBg: $primary-color, $hoverColor: $white, $border: 0, $min-width: 217px);

    margin-bottom: $space-quadruple;
    margin-left: $space-double;

    @media print {
      border: 1px solid;
    }

    @include breakpoint(small only) {
      margin-left: 0;
      margin-top: $space-double;
    }

  }

}