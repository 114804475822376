.view.content-search {
  .view-filters {

    .heading {
      margin-bottom: 0;
    }

    form {
      padding-bottom: $space-quintuple;
      border-bottom: 2px solid $border-color;

      .form-text {
        @extend %input--with-icon;
        @extend %input--with-icon--right;
        @extend %input--with-icon--magnifier;
      }
    }
  }

  .view-content-inner {
    border-top: $input-border;
  }

  .teaser {
    border-bottom: $input-border;

    &__subheading {
      margin-bottom: 0;
    }
  }

  &.view-all-search {
    .view__pager.view-header {
      flex-wrap: wrap;
      margin-top: $space-quintuple;
    }

    .view-content {
      padding: $space-quintuple 0;
    }

    .all-search-header {
      width: auto;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: $space-triple;

      @include breakpoint(xlarge) {
        margin-bottom: 0;
      }
    }
  }
}

.view-search {
  padding-top: $space-quintuple;
}
