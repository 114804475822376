/*
 * This combined file was created by the DataTables downloader builder:
 *   https://datatables.net/download
 *
 * To rebuild or modify this file with the latest versions of the included
 * software please visit:
 *   https://datatables.net/download/#zf/dt-1.10.22
 *
 * Included libraries:
 *   DataTables 1.10.22
 */

// Default style
table.dataTable {
  clear: both;
  max-width: none;
  width: 100%;

  td,
  th {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;

    &.dataTables_empty {
      text-align: center;
    }
  }

  &.nowrap th,
  &.nowrap td {
    white-space: nowrap;
  }

  thead {
    > tr > th:active,
    > tr > td:active {
      outline: none;
    }

    th {
      white-space: nowrap;
    }

    [class*="sorting"] {
      padding-right: 1.5rem;
      cursor: pointer;
      background: transparent none;

      &::after {
        content: '';
        display: inline-block;
        width: 13px;
        height: 16px;
        margin-left: $space;
        vertical-align: text-top;
        background-repeat: no-repeat;
      }
    }

    /* .sorting::after {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000' fill-rule='evenodd'%3E%3Cpath d='M6.5 16l6-7H.5zM6.5 0l6 7H.5z'/%3E%3C/g%3E%3C/svg%3E");
    }

    .sorting_asc::after {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23AAA' d='M6.5 16l6-7H.5z'/%3E%3Cpath fill='%23000' d='M6.5 0l6 7H.5z'/%3E%3C/g%3E%3C/svg%3E");
    }

    .sorting_desc::after {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23AAA' d='M6.5 0l6 7H.5z'/%3E%3Cpath fill='%23000' d='M6.5 16l6-7H.5z'/%3E%3C/g%3E%3C/svg%3E");
    }

    .sorting_asc_disabled::after,
    .sorting_desc_disabled::after {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23AAA' d='M6.5 16l6-7H.5z'/%3E%3Cpath fill='%23AAA' d='M6.5 0l6 7H.5z'/%3E%3C/g%3E%3C/svg%3E");
    } */

    .views-field-secondary-file-category,
    .file-type {
      padding-right: $space-triple;
      .dt-column-order
        {
          position: absolute;
          right: 12px;
          top: 0;
          bottom: 0;
          width: 18px;
          
          &::after {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
            background-position: center center;
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' viewBox='0 0 19 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23000' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M8 11h3M5 6h9M1 1h17'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 0.6;
            display: block;
            content: '';
          }
        }
    }
  }
}

div.dataTables_wrapper {
  position: relative;

  div.dataTables_length {
    label {
      float: left;
      text-align: left;
      margin-bottom: 0;
    }

    select {
      width: 75px;
      margin-bottom: 0;
    }
  }

  div.dataTables_filter {
    label {
      float: right;
      margin-bottom: 0;
    }

    div.dataTables_filter input {
      display: inline-block;
      width: auto;
      margin-bottom: 0;
      margin-left: 0.5em;
    }
  }

  div.dataTables_info {
    padding-top: 2px;
  }

  div.dataTables_paginate {
    float: right;
    margin: 0;
  }

  div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1rem 0;
  }
}

div.dataTables_scrollHead table {
  margin-bottom: 0;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0;
  margin-bottom: 0;

  tbody tr:first-child th,
  tbody tr:first-child td {
    border-top: none;
  }
}

div.dataTables_scrollFoot table {
  margin-top: 0;
  border-top: none;
}

// Custom style
table.dataTable.table-js-sort {
  th,
  td {
    text-align: left;
  }

  th {
    position: relative;
    padding-left: 10px;
    cursor: pointer;

    &.file-size {
      min-width: 30px;
    }
    &.file-date {
      text-align: left;
      min-width: 60px;
    }
  }

  td {
    vertical-align: top;
    line-height: rem-calc(24);

    &:first-child {
      width: 70%;
      line-height: normal;
    }

    &.views-field-secondary-file-category,
    &.file-type {
      width: 20%;
    }
  }

  .file-link {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }

  .file-ext {
    display: block;
    width: 20px;
    height: 24px;
    margin-right: $space-double;
  }

  .time {
    font-size: inherit;
    letter-spacing: normal;
  }

  .dt-checkbox-container {
    position: absolute;
    z-index: 1;
    top: rem-calc(35);
    left: rem-calc(10);
    padding: 25px;
    border-radius: 14px;
    border: 2px solid $medium-lighter-gray;
    background: $white;

    .dt-checkbox {
      @extend %visually-hidden;

      z-index: -1;
      top: (18rem/16);
      left: (18rem/16);
    }

    label {
      position: relative;
      display: block;
      margin: rem-calc(8) 0;
      padding-left: rem-calc(30);
      line-height: 1.25em;
      // Make sure that the label is visible. Some nested elements are
      // interfering with this
      opacity: 1 !important;
      font-size: 1rem;
      cursor: pointer;

      &:first-child {
        margin-top: 0;
      }

      // Input check box
      &::before {
        position: absolute;
        left: 0;
        content: '';
        display: inline-block;
        width: rem-calc(16);
        height: rem-calc(16);
        border-radius: rem-calc(4);
        border: 2px solid $border-color;
        top: 0;
        transition: background-color 0.2s;
      }

      &:hover,
      &:focus {
        &::before {
          background-color: $dark-white;
        }
      }

      // Tick mark
      &::after {
        position: absolute;
        z-index: 10;
        top: rem-calc(3);
        left: rem-calc(3);
        width: rem-calc(14);
        height: rem-calc(14);
        fill: $primary-color;
        transition: all 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
        transform: scale(0, 0);
        content: '';
        display: block;
        // Was a problem to integrate this in Drupal, so base64 to the rescue
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 22' id='check'%3E%3Cpath style='fill: %230041c0' d='M9.2 18.7c-.4 0-.8-.1-1-.4C6.7 16.9 5 15.1 2.9 13l-1-1.1 2.2-2.1 1 1.1C6.6 12.5 8 14 9.2 15.1L22 2.6l2.1 2.1-13.9 13.6c-.2.3-.6.4-1 .4z'%3E%3C/path%3E%3C/svg%3E");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      &.checked {
        &::after {
          transform: scale(1, 1);
        }
      }
    }

    button {
      width: 100%;
      min-width: auto;
      min-height: auto;
      box-sizing: border-box;
      margin-top: rem-calc(10);
      padding: $space 1.5*$space;
    }
  }
}

.table-js-sort--link {
  display: inline-block;
  margin: $space-quadruple auto 0;

  &.hidden {
    display: none;
  }
}
