@import "foundation";

.teaser {
  position: relative;
  background: $body-background;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 0;

  @extend %card-hover;

  // Remove the bottom line inherited from generic 'a' tag styles
  > a {
    background-image: none !important;

    @media print {
      text-decoration: underline !important;
    }
  }

  .media {
    width: 100%;

    img {
      width: 100%;
      display:inline-block;
      vertical-align:middle;
    }

    &.clip-v-center {
      position: relative;
      overflow: hidden;
      height: 396px;

      @include breakpoint(medium) {
        height: 147px;
      }



      img {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

  }



  .content {
    padding: $space-double;

    @media print {
      text-decoration: underline;
    }

    .text-formatted {
      line-height: 1.25;

      p {
        font-size: rem-calc(14) !important;
        line-height: 1.25 !important;
      }
    }
  }

  &__link:not(.specified) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 0;
    transition: box-shadow 200ms ease-out;
    z-index: 2;

    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }
  }

  &.node--view-mode-search-result,
  &.term--view-mode-search-result,
  &.media-entity--view-mode-search-result {
    padding: $space-quadruple 0;
    box-shadow: none;
    transform: none;

    .content {
      padding: 0;
    }

    .teaser__link {
      margin-top: 1.5 * $space;
    }
  }

  &__result-type {
    margin-bottom: $space-one-half;
    color: $medium-gray;
    font-size: $cite-font-size;
    font-weight: $global-weight-bold;
  }

  &.media-entity {
    .file-ext {
      margin-right: 0.5*$space;
      vertical-align: middle;
    }
  }
}

.teaser--constricted {
  .media {
    padding-left: rem-calc(15);
    padding-right: rem-calc(15);
  }

  .content {
    padding: rem-calc(10) rem-calc(15);
  }
}

.teaser__link {
  &.specified {
    @include link-hover-primary;
  }
}

.teaser__subheading {
  font-size: $medium-font-size; // make sure the topline for every teaser card is 14px
  margin-bottom: 0;
}

.teaser__heading {
  line-height: 1.25;
}

.teaser__body {
  // Break the nesting.
  font-size: rem-calc(14) !important;
}

.contact-info-dl {
  margin-bottom: $space;

  &:last-child {
    margin-bottom: 0;
  }

  dt {
    display: inline;
    margin: 0;
    font-weight: $global-weight-normal;

    &::after {
      content: ': ';
    }

    &.no-show {
      display: none;
    }
  }

  dd {
    display: inline;
    line-height: 1.75;

    .link {
      overflow-wrap: break-word;
    }

    &::after {
      content: '';
      display: block;
    }
  }
}
