.text-formatted {
  &:not(:last-child) {
    margin-bottom: 1rem;
  } 

  strong,
  b {
    font-weight: $global-weight-bold;
  }

  em,
  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  s {
    text-decoration: line-through;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  small {
    font-size: 80%;
  }

  p {
    font-size: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    margin: 0 0 $space-double 2.5 * $space;
    list-style: none;
    counter-reset: li;

    li {
      counter-increment: li;
      line-height: 1.8;
      font-size: rem-calc(16);

      strong {
        font-size: inherit;
      }

      &::before {
        content: counter(li);
        display: inline-block;
        width: $space-double;
        margin-left: -2.5 * $space;
        margin-right: 0.5 * $space;
        color: $primary-color;
      }
    }
  }

  ul {
    margin: 0 0 $space-double 0;
    list-style: none;

    li {
      padding-left: 2.5 * $space;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDggMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlPklDT05TL0Fycm93L0J1bGxldHBvaW50IENvcHkgNTwvdGl0bGU+PGcgaWQ9IkRlc2t0b3AiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGcgaWQ9IlBTLURlc2stMDYuMDEtQ29udGVudHBhZ2UwMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI1MiAtMTA1MCkiIHN0cm9rZT0iIzAwNDFDMCIgc3Ryb2tlLXdpZHRoPSIxLjUiPjxnIGlkPSJNT0RVTEVTL1RleHRJbWFnZS9EZXNrIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDY5MCkiPjxwYXRoIGQ9Ik0wIDEzLjg2bDUuMzktNi45M0wwIDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI1MyAzNjEpIiBpZD0iSUNPTlMvQXJyb3cvQnVsbGV0cG9pbnQiLz48L2c+PC9nPjwvZz48L3N2Zz4=)
        no-repeat 0 calc(0.9em - 0.5rem);
      line-height: 1.8;
      font-size: 1rem;
    }
  }

  .table-wrapper {
    overflow-x: auto;

    tbody { /* Specific rules for when the "text-formatted" is inside of a table */
      td {
        p,
        ul li {
          font-size: $medium-font-size;
        }
      }
    }
  }
}

.b1 {
  @extend %b1;
}
