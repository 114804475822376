.form-item {
  /* margin: 1.5*$space 0; */
  position: relative;

  label,
  %label {
    display: inline-block;
    padding: 0 0 $space-one-half $space-double;
    font-size: $cite-font-size;
    font-weight: $global-weight-bold;
    line-height: 1.54;
    color: $medium-gray;
    opacity: 0;
    transition: opacity 0.25s ease-out;

    &.visible {
      opacity: 1;
    }
  }

  .validation-msg {
    @extend %label;

    position: absolute;
    top: 3px;
    left: 0;
    width: 100%;
    color: $alert-color;
    background: $body-background;
    opacity: 1;

    &.out {
      opacity: 0;
    }
  }

  &__description {
    display: block;
    margin-top: $space-one-half;
    padding: 0 $space-double;
    font-size: rem-calc(12);
    color: $medium-gray;
    line-height: 1.4;

    p {
      margin: 0;
      line-height: inherit;
    }
  }
}
