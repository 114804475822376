.service-menu {
  line-height: 1.25rem;

  .service-menu__item {
    padding: 0 $space;
  }

  // This is needed for IE11
  @include breakpoint(large) {
    &.large-simple .service-menu__item {
      flex: none;
    }
  }

  .service-menu__link {
    display: block;
    padding: 1.5 * $space 0;
    color: $blackish-gray;
    line-height: inherit;
    text-align: center;
    background: transparent none;
    font-size: rem-calc(16);

    @include breakpoint(medium down) {
      border: 0;
      outline: 0;
    }

    @include breakpoint(large) {
      @include link($blackish-gray);

      padding-top: 0;
    }
  }
}
