// Copied from foundation-global-styles mixin (ZURB Foundation)
html {
  box-sizing: border-box;
  font-size: $global-font-size;
}

// Set box-sizing globally to handle padding and border widths
*,
*::before,
*::after {
  box-sizing: inherit;
}

// Default body styles
body {
  margin: 0;
  padding: 0;
  background: $body-background;
  font-family: $body-font-family;
  font-weight: $global-weight-normal;
  line-height: $global-lineheight;
  color: $body-font-color;

  @if ($body-antialiased) {
    -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
    -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
  }

  @media print {
    padding-top: 0 !important;
  }
}

img {
  // Get rid of gap under images by making them display: inline-block; by default
  display: inline-block;
  vertical-align: middle;

  // Grid defaults to get images and embeds to work properly
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

// Make sure textarea takes on height automatically
textarea {
  height: auto;
  min-height: 50px;
  border-radius: $global-radius;
}

// Make select elements are 100% width by default
select {
  box-sizing: border-box;
  width: 100%;
  border-radius: $global-radius;
}

// Styles Google Maps and MapQuest embeds properly
// sass-lint:disable-line no-ids
.map_canvas,
.mqa-display {

  img,
  embed,
  object {
    max-width: none !important;
  }
}

// Reset <button> styles created by most browsers
button {
  @include disable-mouse-outline;

  padding: 0;
  appearance: none;
  border: 0;
  border-radius: $button-radius;
  background: transparent;
  line-height: 1;
  cursor: $global-button-cursor;
}

// Prevent text overflow on pre
pre {
  overflow: auto;
}

p {
  margin-bottom: $space-double;
}

// Make reset inherit font-family instead of settings sans-serif
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

// Internal classes to show/hide elements in JavaScript
.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

// This comes form Emulsify
.main {
  display: block; // Fix for IE weirdness
}

ul.menu {
  margin-bottom: 0;
  list-style: none;

  ul {
    margin: 0;
    list-style: none;
  }
}

@include foundation-typography;
@include foundation-xy-grid-classes;
@include foundation-reveal;

.grid-container {
  @include custom-xy-grid-container();
}

.layout-container {
  max-width: $global-site-width;
  margin: 0 auto;
  //overflow: hidden;
}

.media {
  font-size: 0;
}

.center-text {
  text-align: center;
}

table {
  @include table();

  // min-width: 640px;
  // border-top: 1px solid $border-color;
  // border-bottom: 1px solid $border-color;
  font-size: 0.875rem;

  td,
  th {
    // padding: 1.3 * $space $space-triple;
    border: 1px solid $dark-gray;
  }

  th {
    // padding: 2.2 * $space $space-triple;
    border-color: $dark-gray;
  }

  tbody th {
    background: $border-color;
  }

  tbody.reverse-stripes tr {
    &:nth-child(even) {
      background: none;
    }

    &:nth-child(odd) {
      background: $table-striped-background;
    }
  }

  &.series-summary-from-lineup-table {
    tr {
      td:first-child {
        background: $border-color;
        border-color: $dark-gray;
      }
    }
  }

}

.table-show-more {
  display: none;
}

.icon-close {
  @include icon-close;
}

.reveal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

#main-content {
  display: inline;
  border: 0;
}

.layout-content {
  position: relative;
  /* max-width: 80rem;
  margin-left: auto;
  margin-right: auto; */

  &::before:not(.in-iframe) {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: $border-color;
    z-index: -20;
  }

  .page-main-content {
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;

    &.full {
      max-width: none;
    }
  }
}

// TODO got sass compilation error
/*
Error in plugin "sass"
Message:
    src/assets/scss/_base.scss
Error: Selector ":not(.paragraph--type--gallery-item-with-text)" can't have a suffix
    ╷
223 │ .paragraph:not(.paragraph--type--hero):not(.paragraph--type--rotating-banner):not(.paragraph--type--gallery-item-with-text) {
    │                                                                              ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ outer selector
... │
227 │   &--medium {
    │   ━━━━━━━━━ parent selector
*/

.paragraph

/* :not(.paragraph--type--hero):not(.paragraph--type--rotating-banner):not(.paragraph--type--gallery-item-with-text)  */
  {
  padding-top: $space-decuple;
  padding-bottom: $space-decuple;

  &--medium {
    margin-top: $space-sextuple;
  }

  &--gray {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: $space-decuple;
    padding-bottom: $space-decuple;
    background: $dark-white;

    @media print {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &--gray-small {
    padding: 3.5*$space $space-quadruple;
    background: $dark-white;
  }

  &--full-width {
    max-width: 100% !important;
  }

  &.paragraph-overview {
    margin: 0;
  }

  &--in-content {
    margin-top: $space-sextuple;
    margin-bottom: $space-sextuple;
    font-size: rem-calc(20);

    &.paragraph--gray {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: $space-sextuple;
      padding-bottom: $space-sextuple;
      background: $dark-white;

      @media print {
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    &.paragraph--gray-small {
      @include breakpoint(small only) {
        margin-left: 0;
        margin-right: 0;
        padding: 3.5*$space $space-double;
      }
    }
  }

  @media print {
    margin-top: 6 * $space;
    margin-bottom: 6 * $space;
  }

  &--type--gallery-item-with-text {
    margin-top: 0;
    margin-bottom: 0;

    .subheading {
      font-family: $header-font-family;
      font-style: normal;
      font-weight: 700;
      text-rendering: optimizeLegibility;
      margin: 0.4rem 0;
    }

    .teaser {
      background: transparent;
      box-shadow: none;

      &:hover {
        transform: translateY(0);
        box-shadow: none;
      }
    }

  }

}

.paragraph__grid {
  .field__items {
    @extend .grid-x;
    @extend .grid-margin-x;
    @extend .grid-margin-y;

    @include breakpoint(768) {
      justify-content: center; // align the teasers to the center if items < 5
    }

    // Columns
    >* {
      @extend .cell;
      @extend .medium-3;
    }
  }

}

.paragraph__grid--1of5 {
  .field__items {

    @include breakpoint(768) {
      justify-content: center;
    }

    @include breakpoint(575) {
      display: flex;
      flex-wrap: wrap;
    }

    >* {
      width: calc(100% - #{map-get($grid-column-gutter, 'small')}) !important;
      /* add margins left/right for > * otherwise row breaks */
      margin-left: .625rem;
      margin-right: .625rem;

      @include breakpoint(575) {
        width: calc(50% - #{map-get($grid-column-gutter, 'medium')}) !important;


      }

      @include breakpoint(768) {
        width: calc(20% - #{map-get($grid-column-gutter, 'medium')}) !important;

      }
    }
  }
}

input {
  &::placeholder {
    color: $input-placeholder-color;
    font-weight: $global-weight-bold;
  }
}

input[type=reset],
.reset {
  padding: $space 0 $space 2.5*$space;
  border: 0 !important;
  outline: 0;
  font-size: $medium-font-size;
  font-weight: $global-weight-bold;
  color: $primary-color;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='15'%3E%3Cpath d='M14.428 3.184C13.241 1.867 11.474 1.03 9.5 1.03c-3.571 0-6.467 2.736-6.467 6.11v1.797' fill='none' fill-rule='evenodd' stroke='%230041c0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.315 6.063L3.033 8.938.75 6.062M4.572 11.817C5.759 13.134 7.526 13.97 9.5 13.97c3.571 0 6.467-2.736 6.467-6.11V6.062' fill='none' fill-rule='evenodd' stroke='%230041c0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.685 8.938l2.282-2.876 2.283 2.876' fill='none' fill-rule='evenodd' stroke='%230041c0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: left center;
  background-size: auto;
  background-repeat: no-repeat;
  cursor: pointer;

  &.disabled,
  &[disabled] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='15'%3E%3Cpath d='M14.428 3.184C13.241 1.867 11.474 1.03 9.5 1.03c-3.571 0-6.467 2.736-6.467 6.11v1.797' fill='none' fill-rule='evenodd' stroke='%23c1c1c1' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.315 6.063L3.033 8.938.75 6.062M4.572 11.817C5.759 13.134 7.526 13.97 9.5 13.97c3.571 0 6.467-2.736 6.467-6.11V6.062' fill='none' fill-rule='evenodd' stroke='%23c1c1c1' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.685 8.938l2.282-2.876 2.283 2.876' fill='none' fill-rule='evenodd' stroke='%23c1c1c1' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    color: $light-gray;
    cursor: auto;
  }
}

// General definition list styles
.dl--inline {
  @include breakpoint(medium) {
    display: flex;
  }

  .dl-term {
    @include breakpoint(medium) {
      margin-right: 1ch;
    }
  }
}

.dl-def {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.search-text {
  padding-right: $space-quintuple;
  position: relative;
}

.search-text__symbol {
  height: rem-calc(22);
  width: rem-calc(22);
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.table-form-two-col {
  display: block;
  min-width: auto;

  tr {
    display: block;
  }

  tbody {
    display: inherit;
  }

  &,
  * {
    border: 0;
  }

  tr,
  th,
  td {
    background-color: transparent !important;
  }

  td,
  th {
    padding: 0;
  }
}

.download-info {
  margin-left: $space-triple;
}

.sort-icon {
  position: absolute;
  top: 50%;
  right: 2px;
  display: block;
  width: 1.1*$space;
  height: 1.1*$space;
  margin-top: -6px;
  border-radius: 1px;
  background: $black;
  transform: rotate(45deg);
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0.4*$space;
    right: -0.5*$space;
    display: block;
    width: $space-double;
    height: 2px;
    background: $border-color;
    transform: rotate(-45deg);
  }
}

.form-actions {
  margin-top: 1.5*$space;

  .form-submit.button {
    margin-top: $space;
  }
}

// GENERAL PRINT STYLES
%print-grid-container {
  @media print {
    max-width: 190mm !important;
    margin-left: 0 !important;
    margin-right: auto !important;
  }
}

// TODO: check later if needed
// Custom global styles used in Drupal-generated content. That is, markup is
// dictated by Drupal and the styles are applied on top.

// Nesting needed to fight the previous specifcity.
.ui-dialog {
  z-index: 10000;

  @media (max-width: 1000px) {
    width: auto !important;
    left: rem-calc(30) !important;
    right: rem-calc(30) !important;
  }

  max-width: 940px !important;
  width: 100% !important;

  .webform-details-toggle {
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
  }

  .webform-type-fieldset {
    margin-top: 0;
    margin-bottom: rem-calc(15);
  }

  .ui-dialog-content.has-select {
    @media (min-width: 769px) {
      overflow: visible;
    }
  }

  [data-drupal-selector="edit-file-name"] {
    font-weight: 700;
  }

  .ui-widget-header {
    padding-left: 3.5em;
    padding-right: 3.5em;
    padding-top: rem-calc(30);
    background-color: transparent;
    border-radius: 0;
    border: 0;

    @include breakpoint(980px) {
      padding-left: 2.1em;
      padding-right: 2.1em;
    }
  }

  .ui-dialog-title {
    font-size: rem-calc(30);
    line-height: calc(44em/30);
    font-family: $header-font-family;
  }

  .ui-widget-content {
    a {
      color: $primary-color;
    }
  }

  .webform-button--submit {
    font-weight: 700;
    border-color: $primary-color;
    border-width: rem-calc(2);
    font-size: rem-calc(20);
  }

  .form-type-checkbox {
    margin-top: 0;
    margin-bottom: rem-calc(4);
  }

  .ui-dialog-buttonpane {
    border: 0;

    .ui-dialog-buttonset {
      float: none;
      padding-left: 3.15rem;
      padding-right: 2.45rem;
      padding-bottom: rem-calc(28);

      @include breakpoint(980px) {
        padding-left: 1.85rem;
        padding-right: 1.85rem;
      }
    }

    // Get rid of unwanted styles.
    .ui-button {
      width: 100%;

      @include breakpoint(medium) {
        width: auto;
      }

      &.button--ghost {
        font-size: 1.25rem;
        font-weight: 700 !important;

        &:hover {
          border: rem-calc(2) solid $primary-color !important;
          font-size: $primary-color !important;
          font-weight: 700 !important;
        }
      }
    }
  }

  .form-actions {
    margin-top: 0 !important;
  }

  // Close modal button
  .ui-dialog-titlebar-close {
    width: rem-calc(35);
    height: rem-calc(35);
    border: 0;
    background: none;
    margin: 0;
    padding: 0;
    top: rem-calc(10);
    right: rem-calc(10);

    >span {
      left: auto;
      right: auto;
      bottom: auto;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #0041c0;
      display: block;
      transition: 0.5s all;
      background-image: none;
      top: 50%;
      margin: 0 0 7px 0;

      &:first-child {
        transform: translate(2px, -2px) rotate(45deg);
        width: 32px;
      }

      &:last-child {
        transform: translate(2px, -2px) rotate(-45deg);
        width: 32px;
      }
    }
  }
}

.paragraph--type--series-summary-for-lineup {
  @include custom-xy-grid-container();
  margin-top: 50px;
  margin-bottom: 50px;

  .heading--short {
    margin-bottom: 40px;
    width: 100%;
  }
}

.hidden-visual {
  opacity: 0;
  visibility: hidden;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;

  & focusable:active,
  focusable:focus {
    position: static !important;
    overflow: visible;
    clip: auto;
    width: auto;
    height: auto;
  }

}

.hidden {
  display: none;
}