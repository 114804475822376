.background-grey {
  .overview {
    .view-filters {
      background: $dark-white;
    }
  }
}

.overview {
  background: $dark-white;

  .view-filters {
    background: $body-background;
  }
  .view-header.view__pager {
    margin-top: $space-quintuple
  }
  .view-footer.view__pager {
    margin-bottom: $space-quintuple;
  }
  .view-content {
    .teaser {
      margin-bottom: $space-double;

      &:last-child {
        margin-bottom: 0;
      }
    }
    

    @include breakpoint(medium) {
      .view-content-inner {
        @include xy-grid();
        @include xy-gutters($negative: true);

        .teaser {
          @include xy-cell(4);
          @include xy-gutters($gutter-position: top bottom);
        }
      }
    }
  }


  @extend %form-separated;

  .form--inline {

    .form-item {
      margin: 15px 0;
    }

    .form-item,
    &>fieldset {

      // Left form column.
      &:nth-child(even) {
        &:after {
          top: 0
        }
      }
    }
  }

  @extend %form-without-divider;

  .views-exposed-form {
    .form-type-select {
      &>label[for*="field-product-classification"],
      &>label[for*="country-select"] {
        display: none;
      }

      &>label[for*="custom-news-types"] {
        display: inline-block;

        @include breakpoint(medium) {
          display: none;
        }
      }
      &>label[for*="custom-news-types"],
      &>label[for*="event-types"] {
        display: inline-block;

        @include breakpoint(medium) {
          display: none;
        }
      }
    }
  }
}