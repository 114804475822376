/* @import "foundation"; */

$bevel-img-width: $bevel-img * 100%;
$bevel-slope-size: 18vw; //approx 10deg slope
$paragraph-bevelled-height: 55vw;

.paragraph-bevelled {
  position: relative;
  margin: 0 0 $space-decuple;
  display: flex;
  align-items: center;
  padding-bottom: $space-quadruple;

  .anchor-id {
    align-self: flex-start;
  }

  .heading {
    margin-bottom: $space-triple;
    text-align: center;
  }

  &--background-aligned .heading {
    text-align: inherit;
  }

  &__content {
    @include custom-xy-grid-container();
    z-index: 2;

    @include breakpoint($global-width-medium + rem-calc($space-quadruple)) {
      width: $global-width-medium;
    }

    /* @include breakpoint(xlarge) {
      width: $global-width;
    } */

  }

  &--left,
  &--right {
    padding-top: $bevel-slope-size;

    @media print {
      padding-top: 0;
    }

    //the after is fix for vertical center in IE 11
    &::after {
      content: '';
      display: block;
      height: calc(#{$paragraph-bevelled-height} - #{$space-quadruple} - #{$bevel-slope-size});
      font-size: 0;

      @media print {
        display: none;
      }
    }
  }

  &--up,
  &--down {
    @include breakpoint(small only) {
      margin-bottom: 0;

      .paragraph-bevelled__content {
        margin-top: $space-quintuple;
        margin-bottom: $space-sextuple;
      }
    }
  }

  &--background-aligned.paragraph-bevelled--left &__content {
    @include breakpoint(large down) {
      padding-left: calc(#{$bevel-img-width} + 2% - 0.5 * (100% - #{$global-width-medium}));
    }

    @include breakpoint(($global-width-medium + rem-calc($space-quadruple)) down) {
      padding-left: calc(#{$bevel-img-width} + 1%);
    }

    @include breakpoint(small down) {
      padding-left: 0;
    }

    @include breakpoint(xlarge) {
      padding-left: calc(#{$bevel-img-width} + 2% - 0.5 * (100% - #{$global-width}));
    }
  }

  &--background-aligned.paragraph-bevelled--right &__content {
    @include breakpoint(medium) {
      text-align: right;

      .text-formatted {
        ul,
        ol {
          li {
            display: inline;
            background-position: 0 calc(0.5em - 0.5rem);

            &::before {
              margin-right: 1.5*$space;
            }

            &::after {
              content: '';
              display: block;
            }
          }
        }
      }
    }

    @include breakpoint(large down) {
      padding-right: calc(#{$bevel-img-width} + 2% - 0.5 * (100% - #{$global-width-medium}));
    }

    @include breakpoint(($global-width-medium + rem-calc($space-quadruple)) down) {
      padding-right: calc(#{$bevel-img-width} + 1%);
    }

    @include breakpoint(small down) {
      padding-right: 0;
    }

    @include breakpoint(xlarge) {
      padding-right: calc(#{$bevel-img-width} + 2% - 0.5 * (100% - #{$global-width}));
    }
  }

  &--background-aligned .bevel {
    .media picture::after {
      content: none;
    }
  }

  &:not(.paragraph-bevelled--up):not(.paragraph-bevelled--down) {
    margin:  0;
    padding: rem-calc(60) 0;
  }

  &--gray:not(.paragraph-bevelled--up):not(.paragraph-bevelled--down) {
    margin: 0;
    padding: $space-decuple 0;

    @media print {
      padding: 0;
    }
  }

  &--no-image {
    min-height: auto;
  }

  @include breakpoint(medium) {
    .paragraph + &--up,
    .paragraph + &--down,
    .paragraph + link + &--up,
    .paragraph + link + &--down {
      margin-top: -$space-quintuple;
    }
  }
}

.bevel {
  position: relative;
  padding-top: $space-quadruple;

  .paragraph-bevelled & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .media {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: $bevel-img-width;
    overflow: hidden;

    @include breakpoint(medium) {
      display: block;
    }

    @media print {
      display: none;
    }

    .image{
      text-align: right;
    }

    picture {
      display: block;
      position: relative;
      background: $dark-white;
    }

    img {
      width: 100%;
      opacity: 0.6;
      mix-blend-mode: multiply;
    }
  }

  &--down,
  &--up {
    &::before,
    &::after {
      display: none;

      @media print {
        display: none !important;
      }
    }

    &::before {
      top: $space-quadruple;
    }
  }

  &--up {
    &::before,
    &::after {
      display: none;
    }
  }

  &--gray {
    background: $dark-white;

    &::before {
      border-color: transparent $dark-white transparent transparent;
    }
  }

  &--left,
  &--right {
    .media {
      picture::after {
        display: none;
      }
    }
  }

  &--right {
    .media {
      left: auto;
      right: 0;
    }
  }
}

/* Mini 5 Cols Teasers */
.mini-5-cols-teasers {
  max-width: $grid-container-medium; // contrainsts the 5 cols teaser to 940px
}

/* Download Area - Paragraph */
.paragraph-download-area {
  padding-top: $space-quadruple !important;
  padding-bottom: $space !important;

  &:not(:first-of-type) {
    padding-top: $space-double !important;
    padding-bottom: $space-double !important;
  }

  .table-wrapper {

    /* @include breakpoint(medium) {
      min-height: rem-calc(180) !important;
    } */
  }
}
