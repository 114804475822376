.paragraph-card-slider {
  margin-left: $space-double;
  margin-right: $space-double;
  position: relative;

  @include breakpoint(($global-width-medium + rem-calc($space-quadruple))) {
    margin-left: 0;
    margin-right: 0;
  }

  .heading {
    margin-bottom: $space-triple;
  }

  .body {
    @extend %b1;

    margin-bottom: $space-triple;
  }

  &__cta,
  .cta-link {
    @include button--ghost();
  }

  @include breakpoint(small down) {
    &__heading {
      text-align: center;

      .cta-link {
        display: none;
      }
    }

    &__content {
      text-align: center;

      .teaser {
        text-align: left;
      }

      .cta-link {
        margin: $space-quadruple auto 0;
      }
    }
  }

  @include breakpoint(medium) {
    display: flex;

    &__heading,
    &__content {
      width: 50%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    &__heading {
      max-width: 0.5*$global-width-medium;
      margin-left: auto;
      padding-right: 6.3%;

      .cta-link {
        display: inline-block;
      }
    }

    &__content {
      padding-left: $space;

      .cta-link {
        display: none;
      }
    }
  }

  @include breakpoint(medium only) {
    &__heading {
      padding: 0 4% $space-double;
    }
  }

  @include breakpoint(xlarge) {
    &__heading {
      max-width: 0.5*$global-width;
    }
  }

  .slick-slider {
    margin-right: -$space-double;
    position: static;

    @include breakpoint(($global-width-medium + rem-calc($space-quadruple))) {
      margin-right: 0;
    }

    .slick-arrow { // Rebuild the arrows without the need for extra calculations
      margin-top: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  
    .slick-arrow.slick-prev {
      left: -$space-double;

      @include breakpoint(($global-width-medium + rem-calc($space-quadruple))) {
        left: 0;
      }
    }
  
    .slick-arrow.slick-next {
      right: -$space-double;

      @include breakpoint(($global-width-medium + rem-calc($space-quadruple))) {
        right: 0;
      }
    }

    .slick-slide {
      opacity: 0.5;

      @include breakpoint(large) {
        opacity: 0.3;

        &.slick-active {
          opacity: 0.5;
        }
      }

      &.slick-current {
        opacity: 1;
      }
    }

    .slick-dots {
      margin-top: $space-triple;
    }

    .slick-list {
      padding-right: 12%;

      @include breakpoint(medium only) {
        padding-right: 20%;
      }
    }

    &.single-item {
      margin-right: -$space;

      .slick-track {
        width: 100% !important;
      }

      @include breakpoint(small only) {
        .slick-list {
          padding-right: 0;
        }
      }
    }
  }
}
