@import "components/menus/service-menu/_service-menu.scss";
@import "components/menus/main-menu/_main-menu.scss";
@import "components/language-switcher/_language-switcher.scss";

@include foundation-title-bar;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  @include custom-xy-grid-container();

  @include breakpoint(large) {
    min-height: 100px;
    height: auto;
  }

  .logo {
    width: 140px;
    margin-right: $space-double;
    padding: 0;
    border: 0;
    background: none;

    @include breakpoint(large) {
      width: 155px;
      margin-right: 3.5 * $space;
      flex-shrink: 0;
      flex-grow: 0;
    }

    @include breakpoint(xlarge) {
      width: 176px;
      margin-right: 6 * $space;
    }
  }

  .logo-secondary {
    height: 44px;
    padding: 0;
    border: 0;
    background: none;

    @include breakpoint(large) {
      flex-shrink: 0;
      flex-grow: 0;
    }

    img {
      max-height: 100%;
    }
  }

  .menu-trigger {
    order: 1;
    align-self: center;
    width: 45px;
    height: 45px;
    padding: $space-one-half;
    visibility: hidden;

    @include breakpoint(medium down) {
      visibility: visible;
    }
  }

  .header-menu-icon {
    width: 35px;
    height: 35px;
    background: transparent;

    .icon {
      width: 100%;
      height: 2px;
      margin-bottom: 7px;
      background-color: $primary-color;
      display: block;
      transition: 0.5s all;

      &:first-child {
        transform: rotate(0deg);
      }

      &:nth-child(2) {
        opacity: 1;
      }

      &:last-child {
        transform: rotate(0deg);
        margin-bottom: 0;
      }
    }

    &-close {
      .icon {
        position: absolute;

        &:first-child {
          transform: translate(2px, -2px) rotate(45deg);
          width: 32px;
        }

        &:last-child {
          transform: translate(2px, -2px) rotate(-45deg);
          width: 32px;
        }
      }
    }
  }

  .inner-header {
    display: flex;

    .header-search {
      padding: 1.5 * $space;
      line-height: 1.25rem;
      font-size: $medium-font-size;
      font-weight: $global-weight-bold;
      cursor: pointer;
      background: transparent none;
      outline: 0;
      margin-left: rem-calc(20);

      @include breakpoint(large) {
        padding: 0;
      }

      .search-text__icon {
        width: rem-calc(16);
        height: rem-calc(16);
        vertical-align: text-bottom;
      }
    }

    @include breakpoint(medium down) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      flex-direction: column;
      align-items: center;
      padding: $space-triple $space-double;
      background: $body-background;
      z-index: 10;
      overflow-x: hidden;
      overflow-y: auto;

      .logo {
        margin-bottom: $space-triple;
        align-self: flex-start;
      }

      .service-menu {
        order: 2;
        margin-top: 2 * $space;
        flex-shrink: 0;
        flex-grow: 0;
      }

      .language-switcher {
        order: 3;
        padding: 4px 0;
        flex-shrink: 0;
        flex-grow: 0;
      }

      .main-navigation {
        .is-drilldown {
          height: auto !important;
          max-width: none !important;
        }
      }

      .header-search {
        order: 4;
      }

      .logo-secondary {
        margin-top: $space-double;
        margin-bottom: $space-triple;
      }
    }

    @include breakpoint(large) {
      flex-grow: 1;
      flex-wrap: nowrap;
      margin-top: 2.6 * $space;

      .logo, .logo-secondary {
        display: none;
      }

      .service-menu {
        justify-content: flex-end;
        flex-shrink: 0;
        flex-grow: 0;
      }

      .language-switcher {
        margin-top: -1.2 * $space;
        margin-right: 1.038%;
      }

      .main-navigation {
        display: flex;
        margin-right: auto;
      }

      .header-search {
        span {
          display: none;
        }
      }
    }
  }

  .menu-trigger-close {
    visibility: hidden;
    position: fixed;
    right: $space;
    z-index: 10;

    @include breakpoint(medium down) {
      visibility: visible;
    }
  }
}

.header-container {
  .header {
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: #fff;
      top: 0;
      height: 100%;
      width: 50vw;
      z-index: -1;
    }

    &::before {
      left: 50%;
    }

    &::after {
      right: 50%;
    }
  }

  // States
  &.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 400;
    transition: 0.3s transform;
  }

  &.is-up {
    transform: translateY(-100%);
  }

  .anchor-sticky & {
    transform: translateY(-100%);
  }

  &.is-elevated {
    box-shadow: 0 2px 8px 0 rgba(#000, 0.2);
  }

  &.is-down {
    transform: translateY(0);
  }

  &.menu-open {
    height: 100%;
  }

  @media print {
    .layout-container > & {
      position: static;
    }

    .header > *:not(.logo) {
      display: none;
    }

    .logo {
      margin: 5mm 5mm 0;
    }

    .header {
      &::before,
      &::after {
        content: none;
      }
    }
  }
}
