.block--footer-menu {
  color: white;
  background: $secondary-color;

  @media print {
    display: none;
  }

  a {
    @include link($white, 1px);

    font-weight: $global-weight-normal;
    font-size: $small-font-size;
  }

  .menu {
    display: flex;
    justify-content: center;

    @include breakpoint(small down) {
      flex-direction: column;
      align-items: center;
    }

    .menu__item {
      padding: $space 2.5*$space;
      line-height: 1.4;
    }
  }
}
