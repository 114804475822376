@import "components/back-to-top/_back-to-top.scss";


.prefooter {
  &__top {
    @include custom-xy-grid-container();

    text-align: center;

    .back-to-top {
      margin-bottom: $space-sextuple;
    }

    @include breakpoint(medium) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $space-quadruple;
      text-align: left;

      .back-to-top {
        margin: $space $space-double $space 0;
      }

      .social-media-links {
        margin-right: -$space;
      }
    }

    @include breakpoint(medium only) {
      .social-media-links {
        margin-right: -0.5 * $space;
      }
    }

    @media print {
      display: none;
    }
  }

  .paragraph-prefooter-teasers {
    min-height: auto;
    margin: 0;
    padding-bottom: $space;

    text-align: center;

    &__content {
      margin-bottom: $space;
      flex-grow: 1;

      @include breakpoint(medium) {
        margin-bottom: 0;

        .field__items {
          @include xy-grid();
          @include xy-gutters($negative: true);
        }

        .teaser {
          @include xy-cell(4);
        }
      }
    }

    .teaser {
      margin-bottom: $space-double;

      &:hover {
        .media {
          background: $primary-color;

          &::before {
            transform: translate(-50%, -50%) scale(1, 1);
            transition: transform 0.7s ease-out, opacity 0.7s ease-out;
            opacity: 0;
          }
        }
      }

      .media {
        background: $dark-white;
        transition: background 0.2s;
        overflow: hidden;
        position: relative;

        @media print {
          display: none;
        }

        // Bubble
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale(0, 0);
          width: 134%;
          padding-bottom: 134%;
          background-color: lighten($primary-color, 20);
          border-radius: 50%;
          opacity: 1;
        }

        picture {
          display: block;
          width: 140px;
          height: 140px;
          line-height: 140px;
          margin: $space-triple auto;
          background: $white;
          border-radius: 50%;
          position: relative;
          z-index: 1;
        }

        img {
          max-width: 60px;
          max-height: 60px;
          width: auto;
          height: auto;
        }
      }
    }

    @media print {
      text-decoration: underline;
    }
  }
}
