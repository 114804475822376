.paragraph-text-media {
  margin-bottom: $space-sextuple;
  font-size: 1.25rem;

  &__content {
    @include custom-xy-grid-container();
  }

  &__heading {
    margin-bottom: $space-quadruple;

    &:has( + .state) {
      margin-bottom: 0;
    }

  }

  &__inner {
    .text {
      margin-bottom: $space-double;
      max-width: 100%;
    }

    .media {
      position: relative;
      margin-bottom: $space;

      .figure .image {
        margin: 0;
      }

      .figure__caption {
        margin: 0;
      }

      img {
        width: 100%;
        display: block;
        margin: auto;
      }

      video {
        width: 100%;
      }
      
    }

    .cta-link {
      @include button();
    }

    // No tables in content
    @include breakpoint(small down) {
      display: flex;
      flex-direction: column;
    }

    @include breakpoint(medium) {
      overflow: hidden;

      .media {
        float: left;
        width: 48.5%;
        margin-right: 3%;
      }
    }
  }
  &__lightbox {
    .media {
      margin: calc($space/2);
    }
  }

  &:not(&--media-right) {
    .text-formatted {

      ul,
      ol {
        margin-left: 0;

        li {
          display: table;
          padding-left: 2.5 * $space;
        }
      }

      table {
        ul,ol {
          li {
            display: flex; 
          }
        }
      }
    }
  }


  &--media-right &__inner {
    @include breakpoint(small down) {

      //flex-direction: column-reverse;
      .text {
        order: 0;
      }

      .cta-link {
        order: 1;
        margin-bottom: $space-double;
      }

      .media {
        order: 2;
      }
    }

    @include breakpoint(medium) {
      .media {
        float: right;
        margin-left: 3%;
      }
    }

  }

  &--media-right.paragraph-text-media--media-full-width &__inner {
    display: flex;
    flex-direction: column;

    .text {
      order: 0;
    }

    .cta-link {
      order: 1;
      align-self: flex-start;
      margin-bottom: $space;
    }

    .media {
      order: 2;
    }
  }

  &--media-full-width &__inner {

    .media {
      width: 100%;
      float: none;
      margin-left: 0;
      margin-right: 0;
    }
  }



  &--media-border {
    .media {
      border: 2px solid $light-gray;
    }
  }

  &:not(.background-gray)+.paragraph-text-media,
  &:not(.background-gray)+link[data-name="reload"]+.paragraph-text-media {
    margin-top: -$space-double;

    .node--type-job.node--view-mode-full & {
      margin-top: -$space-quintuple;
    }
  }

  .cta {
    margin-top: $space-triple;
  }
}


.state {

  .paragraph-text-media__heading + & {
    margin-bottom: $space-quadruple;
  }
}

/* Remove extra spacing on Seminars page */
.paragraph-seminar-dl-content__content {
  .dl {
    .seminar-content-field {
      margin-bottom: 1rem;

      .paragraph--type--text {
        // Additional information paragraph
        margin: 0;

        .paragraph-text-media__content {
          margin: 0;

          .paragraph-text-media__heading {
            margin-bottom: 0;
          }
        }
      }
    }

    .grid-container {
      // Location paragraph
      margin: 0;
    }
  }
}

.node--type-job-details {
  .paragraph-text-media__inner {
    .image {
      img {
        width: 100%;
      }
    }
  }
}