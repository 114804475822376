.paragraph-product-information {

  margin-top: 0;
  //padding: $space-quintuple 0 8 * $space;
  padding-top: calc(10 * $space);
  padding-bottom: calc(10 * $space);

  &__content {
    @include custom-xy-grid-container();

    @include breakpoint(medium) {
      @include xy-grid();

      .content {
        &__left {
          @include xy-cell(7, $gutters: 0);

          padding-right: 9 * $space;
        }

        &__right {
          @include xy-cell(5, $gutters: 0);
        }
      }
    }
  }

  .subheading {
    font-family: $header-font-family;
    font-weight: $global-weight-bold;
  }

  .body {
    margin-top: $space-quadruple;

    ul {
      margin-left: 0;
    }

    ol {
      margin-left: 1.7*$space;
    }
  }

  .state+.body {
    margin-top: $space-double;
  }

  .media {
    //max-width: 400px;
    padding-top: $space;

    img {
      display: none;
    }

    &>div,
    &>section {
      margin: 0;
    }

    .paragraph-gallery {
      width: 100%;
      margin-top: -$space;

      @include breakpoint(medium) {
        .slick-arrow {
          background: $dark-white;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);

          &:hover,
          &:active {
            background: $primary-color;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

            &::after {
              border-color: $white;
            }
          }
        }
      }

      .content-inner {
        margin: 0;
      }
    }

    @include breakpoint(small only) {
      display: flex;
      justify-content: center;
      margin: $space-quadruple auto 0;
    }

    @include breakpoint(medium) {
      //max-width: 300px;
    }
  }

  &--full-image {
    position: relative;

    .media {
      @include show-as-background;

      max-width: none;
      padding: 0;
    }

    @include breakpoint(small only) {
      display: flex;
      flex-direction: column;

      .media-wrapper {
        order: 1;
        margin: 0 $space-double;
      }

      .media {
        position: static;

        img {
          position: static;
          width: 100%;
        }
      }
    }

    @include breakpoint(medium) {
      .media-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 55%;
        z-index: 0;
      }
    }

    .media-wrapper {
      @media print {
        position: static;
      }

      .media {
        @media print {
          position: static;
        }
      }

      img {
        @media print {
          position: static;
          min-width: auto;
          margin-bottom: $space * 4;
        }
      }
    }

    .paragraph-product-information__content {




      @include breakpoint(medium) {
        .content {
          &__left {
            @include xy-cell(6, $gutters: 0);

            padding-right: 0;
          }
        }
      }
    }
  }
}

.node--type-seminar {
  .paragraph-product-information {
    margin-bottom: 30px;
  }
}