
.slick-slider {
  margin: 0 (-$space);

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    margin: $space;
  }

  .slick-dots {
    @include paging-list;

    > li {
      padding: 0;
      background: $border-color;
      margin: 0 rem-calc(10);
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      border: (1em/16) solid transparent;
      line-height: 0;
      // Safari overflow bug fix
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      -webkit-backface-visibility: hidden;

      &:hover {
        border-color: $primary-color;
        box-shadow: 0 (2em/10) (4em/10) 0 rgba(0, 0, 0, 0.5);
        transition: all 0.2s ease-in;
      }

      &.slick-active {
        color: $white;
        border-color: $primary-color;
        box-shadow: none;

        &::before,
        &::after {
          transition: all 0.3s;
        }

        > button {
          color: currentColor;
        }

        @media print {
          border-color: $black;
        }
      }

      &::before,
      &::after {
        display: block;
        content: '';
        background-color: $primary-color;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: scaleX(0);

        @media print {
          display: none;
        }
      }

      &::before {
        transform-origin: 0 0;
      }

      &::after {
        transform-origin: 100% 0;
      }
    }

    &:not(.direction-left) {
      > li {
        &.slick-active {
          &::before {
            transform: none;
          }
        }
      }
    }

    &.direction-left {
      > li {
        &.slick-active {
          &::after {
            transform: none;
          }

          &::before {
            opacity: 0;
          }
        }
      }
    }

    &.direction-right {
      > li {
        &.slick-active {
          &::before {
            transform: none;
          }
        }
      }
    }

    button {
      background-color: transparent !important;
      cursor: pointer;
      position: relative;
      z-index: 10;

      &:hover {
        color: $primary-color;
      }

      .paragraph-bevelled--gray & {
        background-color: $white;

        &:hover {
          background-color: $primary-color;
        }
      }

      &[aria-label="1 of 1"] {
        display: none;
      }
    }
  }
}

.slider-align-middle {
  margin-bottom: rem-calc(40);

  .slick-arrow {
    margin-top: 0 !important;
    top: 50%;
    transform: translateY(-50%);
  }

  .slick-dots {
    position: absolute;
    top: 100%;
  }
}

.slick-arrow {
  position: absolute;
  top: 0;
  z-index: 10;
  width: $space-quadruple;
  height: 2 * $space-quadruple;
  margin-top: calc(0.66 * (100% - #{$space-double}) - #{$space-triple});// calculated to align with the image in teaser
  background: $dark-white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;
  color: transparent;
  cursor: pointer;
  outline: 0;

  &::after {
    content: '';
    position: absolute;
    top: 3.3 * $space;
    border-radius: 1px;
    border-bottom-left-radius: 3px;
  }

  &.slick-prev,
  &.slick-next {
    @media print {
      font-size: 0;
      color: $black;
    }
  }

  &.slick-prev {
    left: -$space;
    border-radius: 0 40px 40px 0;

    &::after {
      @include border-arrow($direction: left, $size: 15px, $border-width: 3px, $border-color: $primary-color);

      left: 0.7 * $space;
    }
  }

  &.slick-next {
    right: -$space;
    border-radius: 40px 0 0 40px;

    &::after {
      @include border-arrow($direction: right, $size: 15px, $border-width: 3px, $border-color: $primary-color);

      right: 0.7 * $space;
    }
  }

  &:hover,
  &:active {
    background: $primary-color;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    &::after {
      border-color: $white;
    }
  }
}
