.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  animation-delay: 1s;
}

.animated.delay-2s {
  animation-delay: 2s;
}

.animated.delay-3s {
  animation-delay: 3s;
}

.animated.delay-4s {
  animation-delay: 4s;
}

.animated.delay-5s {
  animation-delay: 5s;
}

.animated.fast {
  animation-duration: 800ms;
}

.animated.faster {
  animation-duration: 500ms;
}

.animated.slow {
  animation-duration: 2s;
}

.animated.slower {
  animation-duration: 3s;
}

// @media (print), (prefers-reduced-motion: reduce) {
//   .animated {
//     animation-duration: 1ms !important;
//     transition-duration: 1ms !important;
//     animation-iteration-count: 1 !important;
//   }
// }
