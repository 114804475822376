.no-results-block {
  margin-top: ($space-decuple - $space-one-fifth);
  margin-bottom: ($space-decuple - $space-one-fifth);
  font-size: 1.25rem;

  .heading {
    margin-bottom: $space-double;
  }

  // no-results-block__cta
  &__actions {
    margin-top: $space-triple;

    .cta {
      margin-top: $space-one-fifth;
      margin-bottom: $space-one-fifth;
      margin-right: $space-double;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
