
.form-item select {
  opacity: 0;
}

.select2-container {
  display: block;
  max-width: 100%;
  width: 100% !important;

  &.error {
    .select2-selection {
      border-color: $alert-color;
    }
  }

  &.valid {
    .select2-selection {
      border-color: $success-color;
    }
  }

  .select2-selection,
  .select2-selection.select2-selection--multiple,
  .select2-selection.select2-selection--single {
    height: auto;
    padding: rem-calc(9) 8*$space rem-calc(9) $space-triple;
    border-radius: $input-radius;
    border: $input-border;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    text-align: left;
    outline: 0;

    .select2-selection__rendered {
      padding: 0;
      color: $dark-gray;
      line-height: 2;
    }

    .select2-selection__arrow {
      top: 0;
      right: 0;
      bottom: 0;
      width: 8*$space;
      height: 44px;
      margin: auto;
      border-left: $input-border;

      b {
        position: absolute;
        top: -6px;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 1.5*$space;
        height: 1.5*$space;
        margin: auto;
        border-style: solid;
        border-width: 0 0 3px 3px;
        border-color: $medium-gray;
        border-radius: 2px;
        transform: rotateZ(-45deg);
        transition: 0.5s transform;
      }
    }

    .select2-selection__placeholder {
      color: $input-placeholder-color;
    }
  }


  .select2-selection.select2-selection--single {
    .select2-selection__clear {

      float: none;
      position: relative;
      display: inline-block;
      width: $space-double;
      height: $space-double;
      color: transparent;
      font-size: 0;
      vertical-align: text-bottom;

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 1.8*$space;
        margin-top: $space;
        height: 2px;
        background-color: $primary-color;
        border-radius: 2px;
        overflow: hidden;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }


  .select2-selection--multiple {
    cursor: pointer;

    .select2-selection__rendered {
      display: flex;
      justify-content: flex-start;
      @include truncate-text(50vw);

      @media (min-width: 411px) {
        max-width: 60vw;
      }
    }

    .select2-search--inline {
      flex-grow: 1;

      .select2-search__field {
        width: 100% !important;
      }
    }

    .select2-selection__choice {
      margin: 0 1ch 0 0;
      padding: 0;
      border: 0;
      background: transparent;
    }

    .select2-selection__choice__remove {
      position: relative;
      display: inline-block;
      width: $space-double;
      height: $space-double;
      color: transparent;
      font-size: 0;
      vertical-align: text-bottom;

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 1.8*$space;
        margin-top: $space;
        height: 2px;
        background-color: $primary-color;
        border-radius: 2px;
        overflow: hidden;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 8*$space;
      height: 44px;
      margin: auto;
      border-left: $input-border;
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: -6px;
      right: 0;
      bottom: 0;
      display: block;
      width: 1.5*$space;
      height: 1.5*$space;
      margin: auto 3.2*$space;
      border-style: solid;
      border-width: 0 0 3px 3px;
      border-color: $medium-gray;
      border-radius: 2px;
      transform: rotateZ(-45deg);
      transition: 0.5s transform;
      z-index: 2;
    }
  }

  &.select2-container--open .select2-selection .select2-selection__arrow b,
  &.select2-container--open .select2-selection.select2-selection--multiple::after {
    top: 6px;
    border-width: 0 0 3px 3px;
    border-color: $medium-gray;
    transform: rotateZ(-225deg);
  }
}

.select2-selection {
  transition: box-shadow 0.7s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);

  // On hover
  &:hover,
  .select2-container--open & {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
}

.select2-dropdown {
  border: $input-border;
  border-top: 0;
  border-radius: 0 0 $input-radius $input-radius;
  color: $black;
  text-align: left;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
  background: $body-background;
  overflow: hidden;
  z-index: 10000;

  .select2-search--dropdown {
    padding: $space-double 1.8*$space $space-double;

    .select2-search__field {
      width: 100%;
      height: auto;
      padding: $space;
      border-radius: $input-radius;
      border: $input-border;
      font-size: $input-font-size;
      font-weight: $input-font-weight;
      text-align: left;
      outline: 0;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0);
      transition: box-shadow 0.7s;

      &:hover,
      &:focus {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      }
    }
  }

  &--above {
    border-top: $input-border;
    border-bottom: 0;
    border-radius: $input-radius $input-radius 0 0;
    box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.2);
  }
}

.select2-results__option {
  padding: $space $space-triple;
  white-space: nowrap;
  font-weight: $global-weight-bold;

  .checkbox {
    @include checkbox();

    margin-right: $space;
    vertical-align: -0.4 * $space;
  }

  &[aria-selected=true] .tick {
    @include tick();
  }
}

.select2-container--default {
  .select2-results__option--highlighted[aria-selected] {
    color: $black;
    background-color: $dark-white;
  }

  .select2-results__option[aria-selected=true] {
    color: $light-gray;
    background-color: $white;
  }
}

.shs-widget-container {
  display: block;
}

.select2-search--dropdown {
  padding: $space $space-triple;
}

.inner-header #select2-edit-lang-dropdown-select-results {
  max-height: 100%;
}

.inner-header #select2-edit-lang-dropdown-select-results li {
  padding: 5px 15px;
}
