.paragraph-form-slanted {
  form {
    @include xy-grid-container;
  }

  .form-item {
    label {
      margin-bottom: 0;
    }
  }

  &:not(.background-grey) {
    .bevel--gray {
      background: transparent;
      .media {
        picture {
          background: transparent;
        }
      }
    }
  }

  &.paragraph-bevelled .heading {
    margin-bottom: $space-quintuple;
  }

  &.newsletter-signup .heading {
    margin-bottom: $space-triple;
  }

  .subheading {
    text-align: center;
  }
}
