%h1 {
  font-size: 2.75rem;
  line-height: 1.36;
}

%h2 {
  font-size: 2.125rem;
  line-height: 1.47;
}

%h3 {
  font-family: $body-font-family;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 4.25px;
  line-height: 1.875;
  color: $primary-color;
}

.h3--force {
  font-size: 1rem !important;
  text-transform: uppercase !important;
  color: $primary-color !important;
  letter-spacing: 4.25px !important;
}

%h4 {
  font-family: $body-font-family;
  font-size: 1.25rem;
  line-height: 1.4;
  color: $primary-color;
}

%h5,
%h6 {
  font-size: 1.25rem;
  line-height: 1.4;
}

h3,
.h3 {
  font-family: $body-font-family;
  text-transform: uppercase;
  letter-spacing: 4.25px;
  color: $primary-color;
}

h4,
.h4 {
  font-family: $body-font-family;
  color: $primary-color;
}

.h5 {
  letter-spacing: normal;
  text-transform: none;
}

.heading {
  @include breakpoint(medium) {
    &--short {
      @include xy-cell(6);

      margin-left: auto;
      margin-right: auto;
    }

    &--long {
      @include xy-cell(10);

      margin-left: auto;
      margin-right: auto;
    }
  }

  &--center {
    text-align: center;
  }
}

.node--type-news,
.node--type-press,
.node--type-event,
.node--type-article,
.node--type-seminar,
.node--type-job {
  &.node--view-mode-full {
    font-size: 1.25rem;

    h1 {
      font-size: 1.75rem;
      line-height: 1.5;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 1.42;
    }

    h3 {
      line-height: 1.4;
    }

    h4 {
      line-height: 1.4;
    }

    @include breakpoint(medium) {
      h1 {
        font-size: 2.125rem;
        line-height: 1.47;
      }

      h2 {
        font-size: 1.875rem;
        line-height: 1.47;
      }

      h3 {
        font-size: 1.5rem;
        line-height: 1.47;
      }

      h4 {
        font-size: 1.25rem;
        line-height: 1.4;
      }
    }
  }
}

.node--type-job {
  &.node--view-mode-full .paragraph {
    font-size: 1rem;

    &.paragraph-text-media--cta {
      font-size: 1.25rem;
    }
  }
}

.heading-listing {
  @extend %h2;
}

.article-h2 {
  font-size: 1.5rem;
  line-height: 1.42;

  @include breakpoint(medium) {
    font-size: 1.875rem;
    line-height: 1.47;
  }
}

.block-title {
  @extend %block-title;
}

.article-h3,
legend .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  color: $body-font-color;

  @include breakpoint(medium) {
    font-size: 1.5rem;
    line-height: 1.47;
  }
}

.text-default-size {
  font-size: 1rem;

  p {
    font-size: inherit;
  }
}

.teaser__subheading { // Map location address
  font-size: 0.8rem;
}
