.paragraph-related-resources {
    
    .paragraph {
      
        &__heading,
        &__content  {
          
            @include custom-xy-grid-container();
        }
      
        &__heading {

              margin-bottom: $space-double;
        }
    }

}