
.share-and-author {
  @include custom-xy-grid-container();

  display: flex;
  flex-direction: column;
  padding: $space 0;
  border-top: $input-border;
  border-bottom: $input-border;
  font-size: 1rem;

  .social-media-links,
  .author {
    margin: $space 0;
  }

  @include breakpoint(medium) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .social-media-links {
      margin-right: auto;
    }
  }

  .social-media-links {
    display: flex;
    align-items: center;
    text-align: left;

    &__text {
      margin: 0;
    }
  }

  .author {
    text-transform: uppercase;
    color: $primary-color;
    font-weight: $global-weight-bold;
    letter-spacing: 4.25px;
  }
}
