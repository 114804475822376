.paragraph-teaser-cta {
  @include custom-xy-grid-container();

  text-align: center;

  &__heading,
  &__subheading {
    color: $white;

    @include breakpoint(medium only) {
      padding: 0 15%;
    }

    @include breakpoint(large) {
      padding: 0 25%;
    }
  }

  &__heading {
    margin-bottom: $space-quadruple;
  }

  &__content {
    position: relative;
    padding: $space-quadruple $space-double;
    color: $white;

    @include breakpoint(medium) {
      padding: $space-quadruple;
    }


    @media print {
      background: $dark-white !important;
    }

    .media {
      background-image: linear-gradient(228deg, $primary-color 0%, $secondary-color 100%);

      @include show-as-background;

      img {
        opacity: 0.4;
        mix-blend-mode: multiply;

        @media print {
          display: none;
        }
      }
    }
  }

  &__body {
    @extend %b1;

    font-weight: $global-weight-bold;
  }

  &__cta,
  .cta-link {
    @include button--alt();

    margin-top: $space-quadruple;

    @include breakpoint(large) {
      margin-top: $space-quintuple;
    }
  }


  &.background-gray {

    max-width: 100% !important;

    @include breakpoint(($global-width-medium + rem-calc($space-quadruple)) down) {
      margin-left: 0;
      margin-right: 0;
    }



    .paragraph-teaser-cta {

      &__content {

        @include custom-xy-grid-container();


        position: relative;
        color: $white;
        display: flex;
        justify-content: center;
        flex-direction: column;

        padding: $space-quadruple;
        margin: 0 $space-quadruple;
        margin-left: auto;
        margin-right: auto;

        .media {
          z-index: 1;
        }

        .cta-link {
          position: relative;
          z-index: 2;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &__body,
      &__heading,
      &__subheading {
        position: relative;
        z-index: 2;
      }
    }
  }



}

.block-stock-search {
  .form-item {
    text-align: left;

    label {
      color: $light-gray;
    }
  }

  .cta-link {
    margin-top: $space-quintuple;
  }

  .paragraph-teaser-cta__content,
  .paragraph-teaser-cta__subheading,
  .paragraph-teaser-cta__heading {
    @include breakpoint(large) {
      padding-left: 12.5%;
      padding-right: 12.5%;
    }

    .media {
      background-image: linear-gradient(-45deg, $secondary-color 0%, $primary-color 100%);
    }
  }

  .paragraph-teaser-cta__body {
    @include breakpoint(large) {
      padding: 0 2*$space-quadruple;
    }
  }
}