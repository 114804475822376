.field--type-tablefield {
  @include custom-xy-grid-container();
}

.tablefield-wrapper {
  text-align: center;

  .table-wrapper {
    text-align: left;
    overflow-x: auto;
  }

  .table-show-more {
    display: inline-block;
    margin-top: $space-quadruple;

    .hidden {
      display: none;
    }
  }

  table {
    .more-rows {
      display: none;
    }
  }
}

// Fix the overflow in cases where the table needs to be without scroll
.tablefield-clipped {
  .tablefield-wrapper {
    .table-wrapper {
      overflow: visible;
    }
  }
}
