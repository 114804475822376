.paragraph-map {
    @include custom-xy-grid-container();

    .teaser__subheading {
        margin-bottom: $space-one-half;
    }

    .geolocation-map-container {

        div {
            overflow: visible !important;
        }

        div>div>div>div>div>div>img[src="/assets/img/pins/marker_pan_distributors.svg"] {
            transform: translate(-50%, -100%);
        }

        .geolocation-map-control {
            margin: 1em 0 0 1em;

            input,
            input.form-autocomplete,
            button {
                height: 32px;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
                border-radius: 2px;
            }

            input.form-autocomplete,
            input {
                text-overflow: ellipsis;
                box-sizing: content-box;
                font-size: 14px;
                margin: 0;
                padding: 0 1em;
                z-index: 3;
                border: 0;
                width: auto;
            }

            input.location:focus {
                border-color: #156dfe;
            }

            label,
            .description {
                display: none;
            }

            .form-item {
                margin: 0;
            }

            button {
                color: #fff;
                cursor: pointer;
                border: none;
                z-index: 2;
                font-size: 0;

                &::before {
                    background-size: contain;
                    height: 18px;
                    width: 18px;
                    content: "";
                    display: block;
                    margin: 0 auto;
                }

                &.search,
                &.locate {
                    background: #156dfe;
                    
                    &:disabled {
                        background: #bbbbbb;
                    }   
                }
            }

            button.locate::before {
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4AcVCg45w2bqzwAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAHjSURBVEjHxdY7aFZBEAXg75qIhTEaBU2tpBC7IBgfTSoxQiy0EcFCsRKfRcBCopWSgAgWNmIjdlaiSECwUMHGRtRGErSSCPGBIKKRscj+sFnun9xA0APL3T27s7PMnJ29IkLDNhgRUxExGRH9Te1WaI416VthbVOjKiIWml+Ng9iLbehO/Be8wkPcx6+lOujAYZxDzyKH/IgreNDUwUpcxz5Lw+3k6E9OljmoMFaz+Swms/G7ciMcw6XSa+ngKA4U3B3sxI2Mu4o9KQc5jmConYPuFPMcIxjFTE1IpnEqhTPHhZTDOWSaPZF03mqjhaZ7ImJ3al0ZX0XEzcJ2f2s+T/I99Kf+b+zA14YJ7sNENn6Ek9CZErou6byF99iFwDN8b7Pxegyk/gw2pP72JPPpKiKmFjndcTxpMzdck4MFVbTsqCJiIGX9FlYl/m26NFJJWChEW1N/HL3Z7R7Bz068SOSbLMl9abxYkj/jeVrfm/EvEz8vRI+LcnGm5rRDqXUVt/98sXai7h5sjIjXhZ4PZfPDGT+Y8acLm6cR0VH3HnyqUcQYLmfyy7EplY+zBT+v4JXVtMK1mno0iw/YkhW7zfNKwhzu4uI/Ldf/5cFZ1ifTEv4q2qlo2f4qfrTOhG9Njf4C0EWk9x5z6KYAAAAASUVORK5CYII=);
            }

            button.search::before {
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAABVUlEQVRYw+2YQRGEMAxFkVAJlYAEJCABCUhAQiWshJWABCRUAg7+XsrMTkibNDBdDvtnek0fSfqToQPQPeE8AuK5IDkBGAC8AESctQEIAHxXKTUIgB7ACr0CAHcrCIAJNm0A+ltALkAc2jWlKoKkcnCKCdCR0i3p4lNmroJwPRGEgC6VhGo2gaTXUQUhwEQryKsmUOaZU40WEOoTk8Eb3iTGYgGhcgaQmcRYL4N0BjHl+RnIeDuI1iFJ8EBivC0gm7bRCsGj1ktKIIGx6pohNjNZ9RYQnxliTgHRM1a/XrH4kIEZhEzstT0mgeTmBpJZzemJjgk65naTO9aAPvOFtavAWMqKdjHyhcxIW9quGRVVO2uh/lTr8fWMqbEw1cvzV/AlXXico2c884IgwZhADMY2STBNQDQwzUAkmKYgAoxvClKAGZqDMDBb89IwRjlI0/f/W+ID3tQLQPib2qQAAAAASUVORK5CYII=);
            }
        }

    }
}