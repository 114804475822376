/////////////////////
// General Mixins (put specific ones in component files where applicable)

/// Mixin - Clearfix.
/// Adds clearfix based on http://bourbon.io/docs/#clearfix
/// use example =   @include cleafix

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

/// Mixin - Wrapper
/// Outer container mixin for large screens
@mixin wrapper($container-max-width: $global-width, $outer-container-break: medium, $v-margin: 0, $v-padding: 0, $h-padding: $space, $h-padding-large: $h-padding) {
  max-width: #{$container-max-width};
  width: 100%;
  margin: #{$v-margin} auto;
  padding: #{$v-padding} #{$h-padding};

  @include breakpoint($outer-container-break) {
    padding: #{$v-padding} #{$h-padding-large};
  }

  @include breakpoint($container-max-width) {
    padding-left: calc(#{$h-padding-large} + calc(-50vw + calc(#{$container-max-width} / 2)));
    padding-right: calc(#{$h-padding-large} + calc(-50vw + calc(#{$container-max-width} / 2)));
  }
}

/// Use the breakout mixin for elements that should be edge-to-edge
/// Even when a parent container uses the wrapper mixin
@mixin breakout($v-padding: $space-double) {
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding-left: calc(#{$v-padding} + calc(-50vw + 50%));
  padding-right: calc(#{$v-padding} + calc(-50vw + 50%));
}

/// Mixin - Standard Margin
@mixin margin {
  margin-bottom: 1em;
}

@mixin no-bottom {
  margin-bottom: 0;
}

@mixin border-arrow($direction: bottom, $size: 8px, $border-width: 2px, $border-color: $primary-color) {
  display: block;
  width: $size;
  height: $size;
  border-style: solid;
  border-width: 0 0 $border-width $border-width;
  border-color: $border-color;

  @if $direction == left {
    transform: rotateZ(45deg);
  }
  @else if $direction == right {
    transform: rotateZ(225deg);
  }
  @else if $direction == top {
    transform: rotateZ(135deg);
  }
  @else {
    transform: rotateZ(-45deg);
  }
}

@mixin link($color: $primary-color, $border-size: 2px) {
  font-weight: $global-weight-bold;
  color: $color;
  background-image: linear-gradient($color, $color);
  background-repeat: no-repeat;
  background-position: 0 100%;
  transition: background-size 0.2s;
  background-size: 0 calc(2rem/16);
  padding-bottom: calc(6rem/16);

  &:hover,
  &:active {
    background-size: 100% calc(2rem/16);
    color: $color;
  }
}

@mixin link-hover-primary($color: $primary-color) {
  background-image: linear-gradient($color, $color);
  background-repeat: no-repeat;
  background-position: 0 100%;
  transition: background-size 0.2s;
  background-size: 0 calc(2rem/16);
  padding-bottom: calc(6rem/16);

  &:hover,
  &:active {
    background-size: 100% calc(2rem/16);
    color: $color;
  }
}

@mixin link-active($color: $primary-color) {
  color: $color;
  background-image: linear-gradient($color, $color);
  background-repeat: no-repeat;
  background-position: 0 100%;
  transition: none;
  background-size: 100% calc(2rem/16);
  padding-bottom: calc(6rem/16);

  &:hover,
  &:active {
    background-size: 100% calc(2rem/16);
    color: $color;
  }
}

@mixin button($bg:$primary-color, $color:$white, $hoverBg: $white, $hoverColor: $primary-color, $border: 2px solid $primary-color, $min-width: 247px, $min-heigh: 60px) {
  display: inline-block;
  min-width: $min-width;
  padding: $space 1.5*$space;
  border: $border;
  border-radius: $button-radius;
  color: $color;
  cursor: pointer;
  font-size: rem-calc(16);
  font-weight: $global-weight-bold;
  text-decoration: none;
  text-align: center;
  line-height: 1.3;
  background-color: $bg;
  transition: $button-transition;
  background-image: none !important;

  &:visited {
    color: $color;
  }

  &:hover {
    transform: translateY(-#{rem-calc(4)});
  }

  &:hover,
  &:focus {
    color: $hoverColor;
    background-color: $hoverBg;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }

  &.disabled,
  &[disabled] {
    &,
    &:hover,
    &:focus {
      border-color: $light-gray;
      color: $white;
      background-color: $light-gray;
      cursor: default;
      box-shadow: none;
    }

    &:hover {
      transform: none;
    }
  }

  @media print {
    border-color: $black;
  }
}

@mixin button--ghost {
  @include button($bg:$white, $color:$primary-color, $hoverBg: $primary-color, $hoverColor: $white);
}

@mixin button--alt {
  @include button($bg:$white, $color:$primary-color, $hoverBg: $primary-color, $hoverColor: $white, $border: 0);
}

@mixin search-buttons {
  // @todo Why .search-forms-area class is not used in pages, only in overlay?
  // Copy from _patterns/03-organisms/site/11-site-search-forms-area/search-forms-area.scss
  @include breakpoint(small only) {
    margin-top: $space-quadruple;

    .form-submit {
      width: 100%;
    }
  }

  .form-submit ~ .reset {
    width: auto;
    margin: $space-triple 0 0 0;

    @include breakpoint(medium) {
      margin: 0 0 0 $space-double;
    }
  }

  .form-submit + .ajax-progress {
    margin-left: $space;
  }
}

@mixin show-as-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -10;
  overflow: hidden;

  @media print {
    position: static;
  }

  img,
  video {
    @extend %background-cover-like;
  }
}

@mixin custom-xy-grid-container($width: $grid-container, $padding: $grid-container-padding) {
  @include xy-grid-container($width, $padding);

  @include breakpoint(large down) {
    max-width: $grid-container-medium;
  }

  @include breakpoint(($global-width-medium + rem-calc($space-quadruple)) down) {
    margin-left: $space-double;
    margin-right: $space-double;
  }

  @media print {
    max-width: 190mm;
    margin-left: 0;
  }
}

@mixin custom-slick-in-flex() {
  @include breakpoint(($global-width-medium + rem-calc($space-quadruple)) down) {
    &.paragraph-bevelled {
      display: block;//fix for slick slider bug in flex containers

      &::after {
        content: none;
      }
    }
  }
}

@mixin icon-close($color: $primary-color) {
  width: 35px;
  height: 35px;
  background: transparent;
  cursor: pointer;

  .icon {
    position: absolute;
    width: 100%;
    height: 2px;
    margin-bottom: 7px;
    background-color: $color;
    display: block;
    transition: 0.5s all;

    &:first-child {
      transform: translate(2px, -2px) rotate(45deg);
      width: 32px;
    }

    &:last-child {
      transform: translate(2px, -2px) rotate(-45deg);
      width: 32px;
    }
  }
}

@mixin paging-list($button-size: $space-double, $button-font-size: 0.625rem) {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: $space-double 0 0 0;
  list-style: none;

  li {
    padding: 0 $space;
    line-height: $space-double;
  }

  button,
  .pager-button {
    @include paging-button($button-size, $button-font-size);
  }
}

@mixin paging-button($button-size: $space-double, $button-font-size: 0.625rem) {
  width: $button-size;
  height: $button-size;
  border: 0;
  border-radius: 0.5 * $button-size;
  text-align: center;
  font-size: $button-font-size;
  font-weight: $global-weight-bold;
  line-height: $button-size - 1px;
  color: $primary-color;
  background-color: $dark-white;
  outline: 0;

  &:hover,
  .is-active {
    background-color: $primary-color;
    color: $white;
  }
}

@mixin tick($size: $space-double, $tick-color: $primary-color) {
  display: block;
  width: 0.35*$size;
  height: 0.65*$size;
  margin: 0 auto 0.15*$size;
  transform: rotate(45deg);
  border-bottom: solid 3px $tick-color;
  border-right: solid 3px $tick-color;
  border-bottom-right-radius: 2px;
}

@mixin checkbox($size: $space-double, $border-color: $border-color) {
  display: inline-block;
  width: $size;
  height: $size;
  border: 2px solid $border-color;
  border-radius: 4px;
}

@mixin form--actions {
  display: flex;
  flex-direction: column;
  margin-top: $space-quadruple;

  .submit {
    min-width: initial;
    margin: 0 0 $space-triple;
    outline: 0;
  }

  &__secondary {
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;

    button {
      padding-top: 0.5*$space;
      padding-bottom: 0.5*$space;
      text-align: left;

      &.disabled:hover,
      &[disabled]:hover {
        color: $light-gray;
      }
    }
  }

  @include breakpoint(large) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: $space-triple;

    .submit {
      min-width: 247px;
      margin: 0 $space-double 0 0;
    }
  }
}

/* Truncate Text */
@mixin truncate-text($width) {
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
