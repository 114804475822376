// Menu-based containers
@include foundation-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown;

// Mobile menu (Foundation Drilldown Menu)
@include breakpoint(medium down) {
  .main-navigation {
    width: 100%;
    padding-bottom: 2 * $space;
    border-bottom: 1px solid $border-color;
  }

  .main-menu {
    display: none;
  }

  .main-menu-mobile {
    font-size: $medium-font-size;
    line-height: 1.25rem;

    a {
      border: 0;
      outline: 0;
      display: flex;
      justify-content: center;
    }

    .main-menu-mobile__item {
      .main-menu-mobile__link {
        padding: 1.8 * $space 0;
        text-align: center;
      }
    }
    
    

    .main-menu-mobile--sub {
      height: 100%;

      .main-menu-mobile__link {
        padding: $space-triple 0;
        color: $black;
        text-transform: uppercase;
        letter-spacing: 3.1px;
      }
    }

    .is-drilldown-submenu-parent .main-menu-mobile__link::after {
      content: none;
    }

    .is-drilldown-submenu {
      position: fixed;
      bottom: 0;
      padding: $space-triple $space-double;
      text-align: center;

      &.is-active {
        overflow-x: hidden;
        overflow-y: auto;
      }

      a {
        position: relative;

        &::before {
          position: absolute;
          top: 13px;
          left: 4px;

          @include border-arrow('left', 18px);
        }
      }
    }

    .js-drilldown-back {
      margin-bottom: $space;

      a {
        height: 48px;
        line-height: 48px;
      }
    }
  }
}

@include breakpoint(large) {
  .main-menu-mobile {
    display: none;

    .is-drilldown {
      display: none;
    }
  }

  .main-menu-desktop {
    position: static;
    font-size: rem-calc(18);
    line-height: 1.25rem;
    justify-content: flex-start;
    padding-bottom: $space-double;

    // Main navigation items
    & > li + li {
      margin-left: 2.5 * $space;
    }

    & > li > .main-menu__link {
      @include link-hover-primary();

      position: relative;
      line-height: inherit;
      font-size: rem-calc(16);

      &::after {
        position: absolute;
        bottom: -2.8*$space;
        right: 0;
        left: 0;
        z-index: 20;
        margin: 0 auto;
        width: 1.5*$space;
        height: 1.5*$space;
        transform: rotate(45deg);
        background: $body-background;
      }

      &.hover::after {
        content: '';
      }
    }

    // Submenu items
    .dropdown-pane {
      left: 50% !important;
      right: 0 !important;
      max-height: 70vh;
      width: 100vw;
      padding: 0;
      border: 0;
      border-bottom: 1px solid $dark-white;
      background-color: $dark-white;
      transform: translateX(-50vw);
      overflow-y: auto;

      .main-menu--close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        outline: 0;
      }
    }
  }
}

@include breakpoint(xlarge) {
  .main-menu-desktop {
    // Main navigation items
    & > li + li {
      margin-left: 3.8 * $space;
    }
  }
}

.dropdown-menu-container {
  @include custom-xy-grid-container();
  @include xy-grid();

  position: relative;
  padding-top: rem-calc(60);
  padding-bottom: rem-calc(30);
  box-sizing: content-box;
  overflow: hidden;

  .ul-title a {
    @include link($primary-color);
  }

  a {
    @include link($dark-gray);

    display: inline-block;
    padding-bottom: rem-calc(6);
    font-weight: normal;
    font-size: $medium-font-size;
    line-height: $header-lineheight;
    background-color: transparent;
  }

  ul {
    position: relative;
    min-height: 100%;
  }

  li {
    margin-bottom: rem-calc(15);
    padding: 0 rem-calc(20);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .main-menu--sub {
    background-image: linear-gradient($lighter-gray, $lighter-gray);
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: (1rem/16) 100%;
  }

  .main-menu--sub-1 {
    // left: -21px;
    width: 33.333333%;
    border: 0;
    background: transparent none;

    > li {
      padding-left: 0;
    }
  }

  li.main-menu__item--with-sub {
    > a {
      position: relative;

      &::after {
        @include border-arrow("right", 7px, 1px, $dark-gray);

        content: '';
        background-size: 100% (2rem/16);
        background-color: transparent;
        margin-left: 10px;
        margin-right: 5px;
      }
    }

    ul {
      display: none;
      position: absolute;
      top: 0;
      right: auto;
      left: 100%;
      min-width: 100%;
    }

    &.submenu-active {
      > ul {
        display: block;
      }

      > a {
        @include link-active($primary-color);

        background-color: transparent;

        &::after {
          border-color: $primary-color;
        }
      }
    }
  }

  .ul-title {
    margin-bottom: rem-calc(14);

    h4 {
      font-family: $header-font-family;
      color: $black; // unclickable headings should keep the black color
      display: inline-flex;
      align-items: center;
    }

    a {
      @extend %h4;

      font-family: $header-font-family;

      &:hover {
        @include link-active($primary-color);

        background-color: transparent;

        &::after {
          content: none;
        }
      }
    }
  }

  .back-link {
    position: absolute;
    left: 0;
    top: rem-calc(24);
    padding-left: rem-calc(10);
    font-size: $medium-font-size;
    font-weight: $global-weight-bold;
    color: $primary-color;
    cursor: pointer;

    &::before {
      @include border-arrow("left", 7px, 1px, $primary-color);

      content: '';
      position: absolute;
      top: 0.5em;
      right: auto;
      left: 1px;
      background-size: 100% (2rem/16);
      background-color: transparent;
    }
  }
}

/* "Union Jack" flag icon visible next to menu links which redirect to untranslated pages */
.flag-icon-wrapper {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  order: 2;

  .language-text {
    font-size: 12px;
    color: $dark-gray;
    text-transform: uppercase;
    margin-left: 3px;
    line-height: initial;
  }
}

.main-menu__item {
  a {
    display: inline-flex;
    align-items: center;
  }
}