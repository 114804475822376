.paragraph-teaser-icons {

  &__content,
  &__heading {
    @include custom-xy-grid-container();
  }

  .heading {
    margin-bottom: $space;
  }

  @include breakpoint(medium) {
    &__content {

      .content_inner {
        @include xy-grid();
        @include xy-gutters($negative: true); 
  
        .icon-teaser {
          @include xy-cell(49.9%);
        }
      }
    }
  }

  .icon-teaser {
    position: relative;
    min-height: 80px;
    margin-top: $space-triple;

    &__icon {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      line-height: 80px;
      text-align: center;
      vertical-align: middle;
      background-image: linear-gradient(228deg, $primary-color, $secondary-color);

      img {
        display: inline-block;
        vertical-align: middle;
        max-width: 40px;
        max-height: 40px;
        width: auto;
        height: auto;
      }
    }

    &__inner {
      padding: 2*$space 0 0 100px;
    }

    &__title {
      font-family: $header-font-family;
      font-size: 1.25rem;
      line-height: 1.4;
    }

    &__content {
      padding: 0;

      ul {
        margin-bottom: 0;
      }

      li {
        margin-top: $space-one-half;
      }
    }

    

    @include breakpoint(large) {
      min-height: 100px;

      &__icon {
        width: 100px;
        height: 100px;
        line-height: 100px;

        img {
          max-width: 50px;
          max-height: 50px;
        }
      }

      &__inner {
        padding: 2.5*$space 0 0 120px;
      }
    }
  }

 
}
