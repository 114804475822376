// Status messages
$statuses: (
  "warning": $warning-color,
  "error": $alert-color,
  "status": $primary-color,
) !default;

@each $status-type, $color in $statuses {
  .status--#{$status-type} {
    color: $color;
    display: block;
    margin-bottom: $space-one-half;
    padding: $space-one-half 0;

    .placeholder {
      font-style: normal;
      font-weight: 800;
    }
  }
}
