.paragraph-stock-search {
  /* margin-top: $space-quintuple; */
  /* margin-bottom: $space-quintuple; */
  padding-bottom: 0;

  &__heading {
    @include custom-xy-grid-container();

    .body {
      margin-top: $space-double;
      margin-bottom: $space-double;
      padding-bottom: $space-quintuple;
      border-bottom: 2px solid $border-color;
    }
  }

  .form-fields {
    @include custom-xy-grid-container();

    padding: 0 0 $space-quintuple;
    border-bottom: 2px solid $border-color;

    .form-actions {
      @include search-buttons;
    }

    @include breakpoint(small only) {
      .form-actions {
        text-align: center;
      }
    }

    @include breakpoint(medium) {
      .form-fields-inner {
        @include xy-grid();
        @include xy-gutters($negative: true);

        & > .form-item,
        & > .form-actions,
        & > .clear {
          @include xy-cell(12);
        }

        & > .form-type-select {
          @include xy-cell(6);
        }
      }
    }

    .select2-container {
      width: 100% !important;
    }
  }


  .view.view-stock-search {
    .results-container {
      background: $body-background;
      overflow: hidden;
        .view-header {
          margin-top: $space-quintuple;
        }
        .view-content {
          padding: $space-quadruple 0;
        }
        .view-footer {
          margin-bottom: 0;
        }
    
      .table-wrapper {
        overflow-x: auto;
    
        .product-stock-link {
          &__stock {
            display: block;
            width: rem-calc(45);
            height: rem-calc(45);
            border-bottom-color: transparent;
            background-color: transparent;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='43' height='39'%3E%3Cg transform='translate(1.608 1.009)' fill='none' fill-rule='evenodd'%3E%3Cellipse stroke='%230041C0' stroke-width='2' cx='18.133' cy='32.227' rx='4.049' ry='4.133'/%3E%3Cellipse stroke='%230041C0' stroke-width='2' cx='30.28' cy='32.227' rx='4.049' ry='4.133'/%3E%3Cpath stroke='%230041C0' stroke-width='2' stroke-linejoin='round' d='M9.226 8.258h30.772l-3.24 16.53H12.466z'/%3E%3Cpath stroke='%230041C0' stroke-width='2' stroke-linecap='round' d='M10.04 12.39L7.762 0H0'/%3E%3Cpath d='M31.848 17.992c.414-.414.62-.957.62-1.5 0-.544-.206-1.088-.62-1.502l-3.454-3.454a2.125 2.125 0 00-3.002 0c-.774.773-.824 2-.153 2.832h-6.648c-1.17 0-2.123.953-2.123 2.123 0 1.17.953 2.123 2.123 2.123h6.648a2.126 2.126 0 00.153 2.833 2.125 2.125 0 003.002 0l3.454-3.455zm-5.456 2.454a.702.702 0 01-.207-.5c0-.19.074-.367.207-.5l1.04-1.04a.708.708 0 00-.501-1.207h-8.34a.708.708 0 010-1.415h8.34a.707.707 0 00.5-1.208l-1.039-1.04a.702.702 0 01-.207-.5c0-.188.074-.366.207-.5a.703.703 0 011 0l3.455 3.455a.708.708 0 010 1l-3.454 3.455a.708.708 0 01-1 0z' fill='%230041C0' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: auto;
            transition: transform 0.35s ease-out;
    
            &:hover {
              transform: scale(1.1, 1.1);
            }
          }
        }
      }
    
      table {
        font-size: 1rem;
    
        p {
          margin: 0;
        }
    
        th,
        td {
          width: 18%;
          padding-left: $space-double;
          padding-right: $space-double;
          vertical-align: top;
    
          &:nth-child(1) {
            width: 22%;
          }
    
          &:nth-child(2) {
            width: 8%;
          }
    
          &:nth-child(3) {
            width: 32%;
          }
    
          &:nth-child(4) {
            width: 18%;
          }
    
          &:nth-child(5) {
            width: 20%;
          }
        }
      }
      
    }

  }

}
