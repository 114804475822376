.page-header {
  margin-top: $space-quadruple;
  margin-bottom: $space-quadruple;

  @include breakpoint(small only) {
    margin-top: $space;
    text-align: center;
  }

  &__date,
  &__subheading,
  &__title {
    margin: 0;
  }

  &__date {
    font-size: rem-calc(13);
    letter-spacing: (0.4em/13);
    font-weight: $global-weight-bold;
  }
}
