.paragraph-related-downloads {
  @include custom-xy-grid-container();

  font-size: 1rem;

  .heading {
    margin-bottom: $space-triple;
  }

  ul {
    margin: 0;
    list-style: none;

    li {
      margin-bottom: $space;
    }
  }
}
