.back-to-top {
  border: 0;
  text-transform: uppercase;
  letter-spacing: 4.25px;

  // Remove the bottom line inherited from generic 'a' tag styles
  background-image: none !important;

  &__arrow {
    position: relative;
    display: inline-block;
    width: $space-quintuple;
    height: $space-quintuple;
    margin-right: 0.5 * $space;
    border-radius: 0.5 * $space-quintuple;
    background: $dark-white;
    vertical-align: -$space-double;
    transition: background-color 0.25s ease-out;
    background-image: url('data:image/svg+xml, <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"> <rect fill="%23003b68" width="2" height="17.09" x="23.9" y="19.95"/> <path fill="%23003b68" d="M 34.47,17.79 14.47,17.84 24.93,-0.37 Z" transform="matrix(0.53,0,0,0.38,11.85,13.19)" /></svg>') !important;
  }  
  
  &__text {
    display: inline-block;
    border-bottom: 2px solid transparent;
    transition: border-color 0.25s ease-out;
  }

  &:hover .back-to-top {
    &__arrow {
      background: $primary-color;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-image: url('data:image/svg+xml, <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"> <rect fill="%23DBE2E9" width="2" height="17.09" x="23.9" y="19.95"/> <path fill="%23DBE2E9" d="M 34.47,17.79 14.47,17.84 24.93,-0.37 Z" transform="matrix(0.53,0,0,0.38,11.85,13.19)" /></svg>') !important;
    }

    &__text {
      border-bottom-color: $primary-color;
    }
  }

  @include breakpoint(small only) {
    &__wrap {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }

    &__arrow {
      margin-top: $space-triple;
      margin-right: 0;
    }
  }
}