
.view {
  overflow: hidden;

  .view-filters {
    padding: 0 0 $space-triple 0;

    & > * {
      @include custom-xy-grid-container();
    }

    .body,
    .text {
      margin-top: $space-double;
      margin-bottom: $space-double;
      padding-bottom: $space-quintuple;
      border-bottom: 2px solid $border-color;
    }

    form {
      @include custom-xy-grid-container();

      .form--inline {
        @extend %form--inline;
      }
      
    }
  }

  &.content-search, // All search .view-all-search, Content search .view-search
  &.view-file-search,
  &.view-distributor-search {
    .view-filters .form-actions {
      @include search-buttons;
    }
  }

  .view-content {
    padding: $space-quadruple 0;

    table {
      th,
      td {
        padding-left: $space-double;
        padding-right: $space-double;
      }

      th {
        a {
          position: relative;
          padding-right: $space-double;
          padding-bottom: 0;
          color: $body-font-color;
          border: 0;
          white-space: nowrap;
          background: none;
        }
      }
    }
  }

  .view__pager {
    @include breakpoint(small only) {
      .view-result-info {
        margin-bottom: $space-triple;
        text-align: center;
      }
    }

    @include breakpoint(medium) {
      display: flex;
      align-items: center;

      .pager {
        margin-left: auto;
      }
    }

    &.view-header {
      margin-top: $space-decuple;
    }

    &.view-footer {
      margin-bottom: $space-decuple;
    }

    @media print {
      margin-top: 0 !important;
    }
  }

  .view-header-no-results {
    margin-top: $space-triple;
  }

  .table-wrapper {
    overflow-x: auto;
  }

  .tablesort {
    display: none;
  }
}

.form__title {
  padding: 0 0.625rem;
  display: block;
  width: 100%;
  font-size: 1.5rem;

  * {
    font-size: inherit;
  }
}

// Forms that have vertical (pipe) separator between the columns.
%form-separated {
  .form-submit {
    margin-right: rem-calc(25);
  }

  .form--inline {
    .form-item,
    & > fieldset {

      // Left form column.
      &:nth-child(even):not(.form-actions) {
        @include breakpoint(medium) {
          margin-right: rem-calc(40) !important;
          width: calc(50% - #{rem-calc(50)}) !important; // 40 + 10 = 50
          position: relative;
        }

        &::after {
          @include breakpoint(medium) {
            content: '';
            position: absolute;
            width: rem-calc(1);
            height: 100%;
            background-color: $border-color;
            right: rem-calc(-40);
            /* top: rem-calc(28); */
            transform: translateX(50%);
          }
        }
      }

      // Right form column.
      &:nth-child(odd) {
        @include breakpoint(medium) {
          margin-left: rem-calc(40) !important;
          width: calc(50% - #{rem-calc(50)}) !important; // 40 + 10 = 50
        }
      }

      &:nth-last-child(2) {
        &::after {
          @include breakpoint(medium) {
            top: 0;
          }
        }
      }
    }
    & > fieldset {
      padding: 0;
      border: 0;
      width: 100%;
      @include breakpoint(medium) {
        margin-left: rem-calc(10);
      }
      legend {
        display: none;
      }
      & > div {
        margin-top: -15px;
        .form-item {
          &:nth-child(odd) {
            @include breakpoint(medium) {
              margin-left: 0 !important;
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

%form-without-divider {
  .form-submit {
    margin-right: rem-calc(25);
  }

  .form--inline {
    .form-item {

      // Left form column.
      &:nth-child(even) {
        @include breakpoint(medium) {
          margin-right: rem-calc(40) !important;
          width: calc(50% - #{rem-calc(50)}) !important; // 40 + 10 = 50
          position: relative;
        }
      }

      // Right form column.
      &:nth-child(odd) {
        @include breakpoint(medium) {
          margin-left: rem-calc(40) !important;
          width: calc(50% - #{rem-calc(50)}) !important; // 40 + 10 = 50
        }
      }

      &:nth-last-child(2) {
        &::after {
          @include breakpoint(medium) {
            top: 0;
          }
        }
      }
    }
  }
}





.shs-field-container {
  .shs-widget-container {
    + .shs-widget-container {
      margin-top: rem-calc(10);
    }
  }
}


