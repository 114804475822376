.paragraph-gallery {
  &__content {
    position: relative;
  }

  &__heading,
  &__content .content-inner {
    @include custom-xy-grid-container();
  }

  @include breakpoint(small only) {
    &__content .content-inner {
      /* margin: 0; */
      margin: 0 $space-double;
    }

    &--with-thumbnails &__content .content-inner {
      margin: 0 $space-double;
    }
  }

  .heading {
    margin-bottom: $space-double;
    text-align: center;
  }

  .with-lightbox {
    .field__item {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item-inner {
      position: relative;
    }

    .video {
      min-width: calc(100vw - 60px);
      @media (min-width: 1000px) {
        min-width: 940px;
      }
      @media (min-width: 1440px) {
        min-width: 1200px;
      }
    }
    
  }

  .slick-arrow {
    top: 50%;
    margin-top: -$space-quadruple - $space-double;
    box-shadow: none;
    background: transparent;

    &:hover,:active {
      &::after {

        border-color: $primary-color;  
      }
    }


    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }
  }

  .slick-slider {
    .lightbox-open,
    .caption {
      opacity: 0;
    }
  }

  .slick-initialized {
    .lightbox-open,
    .caption {
      opacity: 1;
    }
  }

  &--with-thumbnails {
    //max-width: 400px;
    margin: 0 auto;

    @include breakpoint(medium) {
      //width: 300px;
      max-width: 100%;
    }

    .gallery {
      .item-inner,
      img {
        width: 100%;
        height: auto;
      }
    }

    .gallery-navigation {
      margin: 0 (-0.6*$space);

      .slick-slide {
        margin: 0.6*$space;
        transition: outline-color 0.25s ease-out, box-shadow 0.25s ease-out;

        &:hover {
          outline: 2px solid $primary-color;
          box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.5);
        }
      }

      &.not-scrollable {
        .slick-track {
          margin-left: 0;
          margin-right: 0;
          transform: none !important;
        }
      }

      .slick-current {
        outline: 2px solid $light-gray;
      }

    }

    

    .slick-arrow {
      margin-top: -$space-quadruple;
      transform: scale(0.5, 0.5);

      &.slick-prev {
        left: -$space;
      }

      &.slick-next {
        right: -$space;
      }
    }

    .with-lightbox .lightbox-open {
      background: transparent;
    }
  }
}

.gallery {
  &.lightbox-slider {

    .field__item {
      margin: calc($space/2);
    }

    .item-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow: hidden;

      & > * {
        overflow: hidden; // IE 11 fix
      }
    }

    .slick-arrow {
      top: 50%;
      margin-top: -$space-quadruple;

      &.slick-prev {
        left: 0;
      }

      &.slick-next {
        right: 0;
      }
    }

    /* .media-title {
      position: absolute;
      top: $space-double;
      left: $space-double;
      outline: 0;
    } */
  }
}

.paragraph-gallery,
.gallery.lightbox-slider {
  .slick-dots {
    flex-wrap: wrap;
    margin-top: $space;
    padding: 0 $space-double;
  }
  /* .slick-slider {
    margin: 0;
    .slick-slide {
      margin: 0;
    }
  } */
  
}

.paragraph--type--gallery-item-with-text {

  .slick-slide & {
    img {
      width: 100%;
      @include breakpoint(medium) {
        width: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .subheading {
    font-family: $header-font-family;
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    margin: 0.4rem 0;
  }

  .teaser {
    background: transparent;
    box-shadow: none;
    &:hover {
      transform: translateY(0);
      box-shadow: none;
    }
  }
}
