// Tab Navigation
.tabs-wrapper {
  background-color: $dark-white;
  
}



.theme-tabs {
  @include custom-xy-grid-container();

  display: flex;
  flex-wrap: wrap;
  margin-top: $space-double;
  margin-bottom: $space-double;
  list-style: none;
  border-bottom: 2px solid $light-gray;

  li {
    margin: 4px 2px -2px 0;
  }

  a {
    @include button($bg:$light-gray, $color:$white, $hoverBg: $primary-color, $hoverColor: $white, $border: 0, $min-width: none, $min-heigh: 40px);

    line-height: 1.5;

    &.is-active {
      background-color: $primary-color;
    }

    &:hover {
      box-shadow: none;
      transform: none;
    }

    @include breakpoint(medium down) {
      padding: 1.5 * $space;
    }
  }

  li.is-active a {
    background-color: $primary-color;
  }
}

.tabs--full {
  display: block;
  padding-left: 2px;
  border-bottom: 0;

  // TODO: Check why the 'breakpoint' mixin isn't wokring
  // @media (min-width: map-get($breakpoints, medium)) {
  @include breakpoint(medium) {
    display: flex;
    flex-direction: row;
  }

  @include breakpoint(small only) {
    margin-right: 0;
    margin-left: 0;
  }

  li {
    display: block;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0 2px 0 0;

    @include breakpoint(medium down) {
      margin-top: 2px;
    }

    a {
      display: block;
    }
  }
}

.tabs--responsive {
  li {
    display: flex;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
    }
  }
}

.tabs--primary {
  margin-bottom: rem-calc(50);
}
