.search-tabs {
  padding: rem-calc(40) 0;
  margin-top: 0;
  margin-bottom: 0;

  &__content {
    border-bottom: 2px solid $white;
  }

  &__heading {
    margin-bottom: 3rem;
    font-size: rem-calc(30);

    @include breakpoint(medium down) {
      margin-bottom: 3rem;
    }
  }

  .paragraph__heading {
    @include custom-xy-grid-container();
  }

  .theme-tabs {
    margin-top: 0;
    margin-bottom: 0;
    background-color: $white;
  }
}
