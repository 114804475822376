@import "foundation";
@import 'global/color-vars';
@import 'global/settings';
@import 'global/extends';

.form-text,
%form-text {
  width: 100%;
  height: auto;
  padding: rem-calc(16) $space-triple;
  border-radius: $input-radius;
  border: $input-border;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  text-align: left;
  background: $body-background;
  outline: 0;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.7s;

  &:hover,
  &:focus {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  &::placeholder {
    transition: 0.7s opacity, 0.7s box-shadow;
    color: $input-placeholder-color;
    display: inline-block;
    opacity: 1;

  }

  /* &.required {
    &::placeholder {
      
        
    }
  } */

  &:focus {
    &::placeholder {
      opacity: 0;
    }
  }

  &.error {
    border-color: $alert-color !important;
  }

  &.valid {
    border-color: $success-color !important;
  }
}

.form-tel,
.form-number,
.form-url,
.form-textarea,
.form-email {
  @extend %form-text;
}

.input-cell {
  position: relative;
}

.input-symbol {
  position: relative;

  &::-ms-clear {
    display: none;
  }

  &~.input-symbol__symbol {
    transition: transform 0.7s, opacity 0.7s;
    transition-delay: 0.35s;
  }

  &~.input-symbol__cta {
    transform: translateY(0);
    opacity: 0;
    transition: transform 0.7s, opacity 0.7s;
    outline: none;
  }

  &.has-value {
    &~.input-symbol__symbol {
      transform: translateY(-100%);
      opacity: 0;
      transition-delay: 0s;
    }

    &~.input-symbol__cta {
      transform: translateY(-50%);
      opacity: 1;
      // Delay on focus
      transition-delay: 0.35s;
    }
  }
}

.input-symbol__symbol {
  position: absolute;
  right: rem-calc(20);
  top: 50%;
  transform: translateY(-50%);
  width: rem-calc(26);
  height: rem-calc(26);
}

.input-symbol__cta {
  position: absolute;
  right: rem-calc(20);
  transform: translateY(-50%);
  top: 50%;
}

// Form with symbols (like: search...)
.form-type-symbol {
  position: relative;
}

// Textarea
.form-textarea {
  min-height: rem-calc(180);
  margin-top: 2.5*$space;
  resize: vertical;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
}

.textarea--spaced-top {
  margin-top: calc(46rem/16);
}

// checkbox
.checkbox__symbol {
  transition: all 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  position: absolute;
  // 'left' and 'top' values are calculated from dimensions of the 'checkbox'
  // and the 'symbol'.
  left: calc(((36rem - 22rem) * 0.5) / 16);
  top: calc(((36rem - 22rem) * 0.5) / 16);
  height: calc(22rem/16);
  width: calc(22rem/16);
  fill: $primary-color;
  z-index: 10;
  transform: translateY(-22%) scale(0, 0);
}

%checkbox,
.form-item .form-checkbox {
  @extend %visually-hidden;

  z-index: -1;
  top: calc(18rem/16);
  left: calc(18rem/16);

  &.error {
    +label {
      &::before {
        border-color: $alert-color;
      }
    }
  }

  +label {
    position: relative;
    padding-left: calc(50rem/16);
    display: inline-block;
    line-height: 1.25em;
    // Make sure that the label is visible. Some nested elements are
    // interfering with this
    opacity: 1 !important;
    font-size: 1rem;
    margin: calc(10rem/16) 0;

    // Input check box
    &::before {
      position: absolute;
      left: 0;
      content: '';
      display: inline-block;
      width: calc(36rem/16);
      height: calc(36rem/16);
      border-radius: calc(8rem/16);
      border: 2px solid $border-color;
      top: 0;
      transform: translateY(-22.22%);
      transition: background-color 0.2s;
    }

    &:hover,
    &:focus {
      &::before {
        background-color: $dark-white;
      }
    }

    // Tick mark
    &::after {
      @extend .checkbox__symbol;

      content: '';
      display: block;
      // Was a problem to integrate this in Drupal, so base64 to the rescue
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 22' id='check'%3E%3Cpath style='fill: %230041c0' d='M9.2 18.7c-.4 0-.8-.1-1-.4C6.7 16.9 5 15.1 2.9 13l-1-1.1 2.2-2.1 1 1.1C6.6 12.5 8 14 9.2 15.1L22 2.6l2.1 2.1-13.9 13.6c-.2.3-.6.4-1 .4z'%3E%3C/path%3E%3C/svg%3E");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  &:checked {
    +label {
      &::after {
        transform: translateY(-36%) scale(1, 1);
      }
    }
  }
}

.checkbox--primary {
  +label {
    margin-bottom: calc(56rem/16);
  }
}

.checkbox-container {
  position: relative;
}

// radio
.form-item .form-radio {
  @extend %checkbox;

  +label {
    &::before {
      border-radius: 50%;
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      width: calc(15rem/16);
      height: calc(15rem/16);
      border-radius: 50%;
      background-color: $primary-color;
      left: calc(((36rem - 15rem) * 0.5) / 16);
      top: calc(((36rem - 15rem) * 0.5) / 16);
      transition: all 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
      opacity: 0;
      transform: translateY(-22%) scale(0, 0);
      transform-origin: 50% 50%;
    }
  }

  &:checked {
    +label {
      &::after {
        opacity: 1;
        transform: translateY(-52.8%) scale(1, 1);
      }
    }
  }
}

.form-item {

  &.form-type-file {

    label {
      display: block;
      opacity: 1;
    }
    .form-file {
      width: 100%;
    
      &::file-selector-button {
        min-width: 247px;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        text-decoration: none;
        text-align: center;
        line-height: 1.3;
        padding: 10px 15px;
        border: 2px solid;
        border-color: $primary-color;
        color: $white;
        background-color: $primary-color;
        transition: background-color 0.25s ease-out, color 0.25s ease-out, transform 0.25s ease-out, box-shadow 0.25s ease-out, -webkit-transform 0.25s ease-out, -webkit-box-shadow 0.25s ease-out;
      }
      &::file-selector-button:hover {
        color: $primary-color;
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      }
    
    }
  }
}


.js input.form-autocomplete {
  background-image: none;
}


.form-item input.search-field {
  @extend %input--with-icon;
  @extend %input--with-icon--right;
  @extend %input--with-icon--magnifier;
  font-size: rem-calc(14);

  @include breakpoint(768) {
    font-size: rem-calc(16);
  }
}

.input-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.input-group> :first-child,
.input-group> :first-child.input-group-button>* {
  border-radius: 0 0 0 0;
}

.input-group> :last-child,
.input-group> :last-child.input-group-button>* {
  border-radius: 0 0 0 0;
}

.input-group-label,
.input-group-field,
.input-group-button,
.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.input-group-label:first-child {
  border-right: 0;
}

.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0px;
  -ms-flex: 1 1 0px;
  flex: 1 1 0px;
  min-width: 0;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}