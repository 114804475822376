.paragraph-top-category-teaser {
  @include breakpoint(small only) {
    text-align: center;
  }

  &.no-padding {
    padding-top: 0rem !important;
  }

  &.paragraph-bevelled {
    .bevel {
      .media {
        picture {
          height: 100%;
          img {
            height: 100%;
            width: auto;
            max-width: none;
          }
        }
      }
    }
  }

  .field__items {
    @include xy-grid();
    @include xy-gutters($negative: true);

    &.field__items--3-col {
      .teaser {
        @include breakpoint(medium) {
          @include xy-cell(4); 
        } 
      }
    }

    .teaser {
      @include xy-cell(49.999%);
      @include xy-gutters($gutter-position: top bottom );
      /* @include xy-gutters($gutter-position: right , $negative: true ); */
  
      @include breakpoint(medium up) {
        @include xy-cell(6);
        @include xy-gutters($gutter-position: right left);
      }
  
  
      box-shadow: none;
      transition: background-color 0.25s ease-out, transform 0.2s;
  
      &:hover {
        background: $primary-color;
  
        .teaser__heading {
          color: $white;
        }
      }
  
      &__heading {
        margin: 0;
        text-align: center;
      }
  
      .content {
        padding-top: $space-triple;
        padding-bottom: $space-triple;
      }
    }


  }

  &:not(.background-gray) {
    .bevel--gray {
      background: transparent;
      .media {
        picture {
          background: transparent;
        }
      }
    }
  }

  &.background-gray {
    .paragraph-bevelled {
      .bevel {
        z-index: 0;
      }
    }
  }

  &__content {
    width: 100%; // IE 11 fix

    @media print {
      text-decoration: underline;
    }
  }

  
}
