// Project
$primary-color: #003b68;
$secondary-color: #003b68;
$success-color: #4fad26;
$warning-color: #e6b923;
$alert-color: #e63323;
$yt-color: #FF0000;

// Grayscale
$white: #fff;
$dark-white: #DBE2E9;
$lighter-gray: #eaeaea;
$light-gray: #c1c1c1;
$medium-lighter-gray: #a3a3a3;
$medium-gray: #7a7a7a;
$dark-gray: #343434;
$blackish-gray: #282828;
$black: #000;
$border-color: #d8d8d8;

$color-border: $light-gray;