.pager {
  &__items {
    @include paging-list($button-size: 3.5*$space, $button-font-size: 1rem);

    margin: 0;

    .pager__item {
      padding: 0;

      a {
        border: 0;
        color: $body-font-color;

        &:hover {
          color: $primary-color;
        }
      }

      .inactive {
        color: $light-gray;
        font-weight: $global-weight-bold;
      }

      .pager-button {
        font-weight: $global-weight-normal;
        line-height: 3.5*$space;
        color: $body-font-color;
        background-color: transparent;
        background-image: none !important;

        &:hover {
          font-weight: $global-weight-bold;
          color: $primary-color;
          background-color: transparent;
        }
      }

      &.is-active .pager-button {
        background-color: $primary-color;
        color: $white;
      }

      &--previous {
        margin-right: $space;
      }

      &--next {
        margin-left: $space;
      }
    }
  }
}

.pager-button {
  display: inline-block;
}
