.figure {
  display: block;

  .image {
    display: block;
    max-width: 100%;
    height: auto;
    margin-bottom: 1.5*$space;
  }
}

.figure__caption {
  margin-bottom: $space-triple;
  font-size: 0.875rem;
}
