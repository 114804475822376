.paragraph--type--product-search {

  &.paragraph {
    padding-top: 0;
    padding-bottom: rem-calc(30);
  }


  background: $dark-white;

  &-container {
    @include custom-xy-grid-container();
  }

  .heading {
    margin-bottom: 0;
    text-align: center;
    padding-top: rem-calc(15);
  }

  .product-search-form {
    display: flex;
    flex-direction: column;

    @include breakpoint(960) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
    }

    .form-item {
      margin: 0;
      width: 100%;
      margin-bottom: rem-calc(30);

      @include breakpoint(960) {
        max-width: rem-calc(426);
        margin-bottom: 0;
      }

      .search-field {
        font-size: rem-calc(14);

        @include breakpoint(768) {
          font-size: rem-calc(16);
        }
      }
    }

    .product-search--cta-wrapper {
      display: flex;
      flex-direction: column;
      height: auto;

      @include breakpoint(960) {
        flex-direction: row;
        align-items: center;
        height: rem-calc(54);
      }

      .form-submit {
        margin-left: 0;
        margin-right: 0;

        @include breakpoint(960) {
          margin-left: rem-calc(20);
          margin-right: rem-calc(40);
        }
      }

      .product-search--links-wrapper {
        border-left: 1px solid $border-color;
        display: flex;
        flex-direction: column;
        padding-left: rem-calc(20);
        margin-top: rem-calc(20);

        @include breakpoint(960) {
          margin-top: 0;
          padding-left: rem-calc(40);
        }

        a {
          display: inline-block;
          max-width: max-content;
        }
      }
    }
  }
}