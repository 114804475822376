.paragraph-x-column-teasers {
  @include custom-slick-in-flex();

  .field__items {
    &--center {
      justify-content: center;
    }

    &.field__items--3-col {
      .teaser {
        background-color: $dark-white;
      }
    }
  }

  &.background-gray {
    .field__items {
      &.field__items--3-col {
        .teaser {
          background-color: $white;
        }
      }
    }
  }

  .cta-link {
    margin-top: $space-triple;
    @include button();

    @include breakpoint(medium) {
      margin-top: $space-double;
    }
  }

  .teaser {
    margin-bottom: $space-double;

    .content {

      .teaser__subheading,
      .subheading {
        font-size: $medium-font-size;
        margin-bottom: 0;
      }
    }
  }

  &.paragraph-bevelled {
    .heading {
      margin-bottom: $space-quadruple;
    }
  }

  /* &__content {
      width: 100%; // IE 11 fix

    } */

  @include breakpoint(medium) {
    .field__items {
      @include xy-grid();
      @include xy-gutters($gutters: $grid-margin-gutters, $negative: true, $gutter-position: left right);

      &.slick-slider {
        display: block;
      }

      .teaser {
        @include xy-cell(3);
      }

      &.field__items--2-col {

        .teaser {

          @include breakpoint(medium) {
            @include xy-cell(6);
          }
        }
      }

      &.field__items--3-col {

        .teaser {

          @include breakpoint(medium) {
            @include xy-cell(33.33%);
          }
        }
      }
    }
  }

  /* 5-column-miniteasers fix */
  &--miniteasers {
    .field__items {
      .teaser {
        /* @include breakpoint(small up) {
          margin-left: .29531rem;
          margin-right: .29531rem;
        } */
      }
    }
  }

  .slick-arrow {
    // Rebuild the arrows without the need for extra calculations
    margin-top: 0;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    box-shadow: none;

    @include breakpoint(small only) {
      display: none !important;
    }

  }

  .slick-arrow.slick-prev {
    left: 0;
  }

  .slick-arrow.slick-next {
    right: 0;
  }

  .slick-slider {
    position: static;
  }

  .slick-slider .slick-dots {
    width: 100%;
  }
}