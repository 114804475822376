.tabs-title {
  a {
    outline: 0;
    span {
      margin-left: $space;
    }
  }
}

.tabs-content {
  

  position: relative;
  padding: $space-quadruple 0;
  transition: all 0.5s ease;
}

.tabs-panel {
  display: none;

  &.is-active {
    display: block;
  }

  // nested tabs
  .tabs-wrapper {
    background-color: $white;
    

  }
  .paragraph {
    padding: 0 0;
  }

  >.paragraph {
    padding-top: $space-quintuple;
  }

  .theme-tabs {
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    border-bottom: 2px solid $light-gray;
    ul {
      border-bottom: 2px solid $border-color;
    }
  
    li {
      margin: -1px 0px -1px -2px;
      

      a {
        /* @include button($bg:$white, $color:$primary-color, $hoverBg: $light-gray, $hoverColor: $white, $border: 0, $min-width: none, $min-heigh: 40px); */
        background-color: $white;
        color:$primary-color;
        border: 1px solid $border-color;
        min-height: 40px;
        &:hover {
          background-color: $light-gray;
          color: $white;
        }
  
        line-height: 1.5;
      }

      &.is-active {
      
        a {
          background-color: $light-gray;
          color: $white;
        }
      } 
    }
  
  }
}
