.paragraph--type--rotating-banner {
  margin: 0;
  position: relative;
  padding: 0 0;

  .slick-slide {
    /* margin-bottom: 0;
    margin-top: 0; */
    margin: 0;

    + .slide {
      display: none;
    }
  }

  .slick-list {
    .slick-slide {
      + .slide {
        display: block;
      }
    }
  }

  .slick-arrow {
    margin-top: 0;
    opacity: 0.62;
    top: calc(50% - #{$space-quadruple});

    &:active,
    &:hover {
      opacity: 0.9;
    }

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }

    @include breakpoint(small only) {
      display: none !important;
    }
  }

  .slick-dots {
    width: 100%;
    position: absolute;
    bottom: 16px;

    li {
      opacity: 0.85;

      &:active,
      &:hover {
        opacity: 1;
      }
    }

    button {
      height: 18px;
      width: 18px;
    }
  }
}
