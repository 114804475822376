@charset 'utf-8';


@import 'global/color-vars';
@import 'global/settings';
@import 'foundation';
@import 'motion-ui';
@import 'global/extends';


// base





// These styles are applied to a <meta> tag, which is read by the Foundation JavaScript
.foundation-mq {
    font-family: '#{-zf-bp-serialize($breakpoints)}';
  }

// Global styles
/* @include foundation-global-styles;*/
/* @include foundation-forms;  */
/*@include foundation-typography; */


// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
//@include foundation-button;
/* @include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-range-input;
@include foundation-switch;
@include foundation-table; */
// Basic components
@include foundation-badge;
/* @include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip; */

// Containers
/* @include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;*/
/*@include foundation-thumbnail; */
// Menu-based containers
/* @include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu; */

// Layout components
/* @include foundation-off-canvas; */
@include foundation-reveal; 
@include foundation-sticky;
/*@include foundation-title-bar;
@include foundation-top-bar; */

// Helpers
@include foundation-float-classes;
/*@include foundation-flex-classes;*/
@include foundation-visibility-classes; 
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

// project specific

@import 'components/base';
@import 'components/text/headings';
@import 'components/icons';
@import 'components/link';
@import 'components/text/text-formatted';
@import 'components/buttons';
@import 'components/video';
@import 'components/iframe';
@import 'components/time';
@import 'components/state';
@import 'components/forms/form';
@import 'components/forms/form-element';
@import 'components/forms/inputs/autocomplete';
@import 'components/forms/inputs/input';
@import 'components/forms/select/select';
@import 'components/status/status';
@import 'components/load-spinner/load-spinner';
@import 'components/figures/figures';
@import 'components/site/site-header/site-header';
@import 'components/site/site-footer/site-footer';
@import 'components/site/site-prefooter/site-prefooter';
@import 'components/site/social-media-links/social-media-links';
//@import 'components/magento-form/marketo-form';
@import 'components/node-full';
@import 'components/image/image';
@import 'components/slider/slick-slider';
@import 'components/slider/rotating-banner';
@import 'components/menus/breadcrumbs/breadcrumbs';
@import 'components/lightbox/lightbox';
@import 'components/menus/tabs/tabs';
@import 'components/tabs/content-tabs';
@import 'components/blocks/sticky-sidebar';


// modules
@import 'components/menus/anchor-links/anchor-links';
@import 'components/paragraphs/paragraphs';
@import 'components/teaser/teaser';
@import 'components/share/share-and-author';
@import 'components/site/search-tabs/search-tabs';
@import 'components/view/views';


// backend
@import 'components/backend';

// product-finder
//@import 'components/product-finder/product-finder.scss';



@import "components/utilities.helpers";



