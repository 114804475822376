@import "components/menus/footer-menu/_footer-menu.scss";
@import "components/menus/footer-site-structure-menu/_footer-site-structure-menu.scss";

.footer {
  clear: both;

  .block--footer-menu {
    padding: $space-sextuple 0 $space-double;

    @include breakpoint(medium) {
      padding: 1.5*$space 0 $space-one-half;
    }
  }

  .copyright {
    padding-bottom: $space-sextuple;
    color: white;
    font-size: $small-font-size;
    text-align: center;
    background: $secondary-color;

    @include breakpoint(medium) {
      padding-bottom: $space-double;
    }

    .copyright-inner {
      @include xy-grid-container();

      @extend %print-grid-container;

      padding: $space 0;
    }
  }
}
