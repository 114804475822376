
.breadcrumb {
  display: flex;
  padding: $space-one-half 0;
  font-size: 13px;
  line-height: 1.6;
  color: $medium-lighter-gray;
  opacity: 0;
  margin-left: 0;

  &.breadcrumb-visible {
    opacity: 1;
  }

  &-inner {
    display: flex;
    flex-shrink: 1;
    overflow: hidden;
    min-width: 0;

    &.breadcrumb-visible {
      min-width: 12px;
    }
    
  }

  &__item {
    flex-shrink: 0;
    max-width: 100%;
    white-space: nowrap;

    &.item-visible {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__link {
    @include link($medium-lighter-gray, 1px);

    font-weight: $global-weight-normal;

    .breadcrumb-visible & {
      display: inline;
    }
  }

  &__arrow {
    @include border-arrow("right", 6px, 2px, $medium-lighter-gray);

    display: inline-block;
    margin: 0 0.4*$space 0 0.6*$space;
    border-radius: 1px;
    vertical-align: 1px;

    &__first {
      display: inline-block;
    }
  }

  &__text {
    color: $light-gray;
  }
}

.breadcrumb-nav {
  @include custom-xy-grid-container();

  padding: $space-double 0;
  ol {
    margin-bottom: 0;
  }
}

.breadcrumb-white,
.paragraph--type--rotating-banner .block-system-breadcrumb-block,
.paragraph-hero .block-system-breadcrumb-block {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 31%, rgba(0, 0, 0, 0.31) 69%);

  .breadcrumb {
    color: $white;

    &__link {
      color: $white;

      &:hover {
        border-color: $white;
      }
    }

    &__arrow {
      border-color: $white;
    }
  }
}

.paragraph-hero,
.paragraph--type--rotating-banner,
.paragraph-overview,
.paragraph-thank-you {
  position: relative;

  .block-system-breadcrumb-block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    @media print {
      position: static;
    }
  }
}

.paragraph-product-information {
  .breadcrumb-nav {
    margin-top: -$space-quintuple;
    margin-bottom: -$space;
  }

  &--full-image {
    .breadcrumb-nav {
      margin-bottom: $space-triple;
    }
  }

  .block-system-breadcrumb-block {
    position: relative;
    z-index: 1;
  }
}
