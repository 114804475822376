.block--footer-site-structure-menu {
  position: relative;
  padding: $space-quadruple;
  padding-top: rem-calc(80);
  color: $white;
  background-color: $secondary-color;

  @media print {
    display: none !important;
  }

  a {
    @include link($white, 1px);

    display: inline;
    font-weight: $global-weight-normal;
    font-size: $small-font-size;
  }

  .menu-container .menu-container-inner {
    @include xy-grid();
    @include xy-gutters($gutters: $grid-margin-gutters, $negative: true, $gutter-position: left right);

    @include breakpoint(small down) {
      & > .footer-site-structure-menu {
        @include xy-cell(6);

        margin: 0 auto;

        display: flex;
        flex-direction: column;
        word-break: break-word;

        &:nth-child(odd) {
          align-items: flex-end;
        }

        & > li {
          display: block;
          max-width: 100%;
          width: 180px;
        }
      }

      
    }

    @include breakpoint(medium) {
      

      & > .footer-site-structure-menu {
        @include xy-cell(2);

        display: block;
        word-break: break-word;
      }
    }

    li {
      width: 100%;
      margin-bottom: $space;

      a {
        line-height: inherit;
      }
    }

    & > .menu {
      & > li {
        margin-bottom: $space-quintuple;

        @include breakpoint(medium) {
          margin-bottom: $space-quadruple;
        }

        & > a {
          display: inline-block;
          margin-bottom: $space-double;
          border: 0;
          font-size: $medium-font-size;
          font-weight: $global-weight-bold;
          text-transform: uppercase;
          line-height: 1.43;
          letter-spacing: 3.1px;
          vertical-align: top;
          text-decoration: none;
        }
      }
    }
  }
}
