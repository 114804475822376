.paragraph-downloadcenter-form {
    margin-bottom: $space-sextuple;
    font-size: 1.25rem;

    .paragraph__content {
        @include custom-xy-grid-container();

        .section-1 {

            @include xy-grid();
            @include xy-gutters($gutters: $grid-margin-gutters, $negative: true, $gutter-position: left right);

            .area {
                @include xy-cell(100%);

                @include breakpoint(medium) {
                    @include xy-cell(6);
                }

                .webform-checkboxes {
                    column-count: 2;
                    column-gap: 2rem;

                    .form-item .form-checkbox+label {
                        position: relative;
                        padding: 0 0 0 2.5rem;
                        display: inline-block;
                        line-height: 1.25em;
                        opacity: 1 !important;
                        margin: 0.625rem 0;
                        font-size: 0.8125rem;
                        font-weight: bold;

                        &::before {
                            width: 1.25rem;
                            height: 1.25rem;
                            border-radius: 0.2rem;
                            border: 2px solid #d8d8d8;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        &::after {
                                // 'left' and 'top' values are calculated from dimensions of the 'checkbox'
                                // and the 'symbol'.
                                left: 0;
                                top: 50%;
                                height: 1rem;
                                width: 1rem;
                            }
                    }

                    .form-item .form-checkbox:checked {
                        +label::after {
                                transform: translateY(-50%) translateX(15%) scale(1, 1);
                            }
                        
                    }
                }
            }

        }

        .section-2 {

            @include xy-grid();
            @include xy-gutters($gutters: $grid-margin-gutters, $negative: true, $gutter-position: left right);

            .area {
                @include xy-cell(100%);

                @include breakpoint(medium) {
                    @include xy-cell(6);
                }

                .form-actions {
                    margin-top: 2.5*$space
                }
            }
        }

        .form-actions {
            @include search-buttons;
            
            .form-submit.reset {
                width: auto;
                margin: $space-triple 0 0 0;
            
                @include breakpoint(medium) {
                  margin: 0 0 0 $space-double;
                }
              }
        }

        .select2-container .select2-selection, 
        .select2-container .select2-selection--multiple{
            display: grid;
        }

        @include breakpoint(small only) {
            .form-actions {
                text-align: center;
            }
        }
    }

    .paragraph__heading {
        @include custom-xy-grid-container();
        margin-bottom: $space-quadruple;
    }
}

.paragraph-downloadcenter-table {
    margin-bottom: $space-sextuple;
    font-size: 1.25rem;

    .paragraph__content {
        @include custom-xy-grid-container();
    }

    .paragraph__heading {
        @include custom-xy-grid-container();
        margin-bottom: $space-quadruple;
    }
}