/**
 * @file
 * Throbber.
 */

.ajax-progress {
  display: inline-block;
  padding: 0;

  .throbber {
    position: relative;
    display: inline-block;
    padding: 0;
    width: 18px;
    height: 18px;
    font-size: 0;
    background: none;
    vertical-align: text-bottom;
    animation: container-rotate 1568ms linear infinite;

    .spinner-layer {
      position: absolute;
      width: 100%;
      height: 100%;
      border-color: $success-color;
      opacity: 1;
      animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;

      .circle-clipper {
        position: relative;
        display: inline-block;
        width: 50%;
        height: 100%;
        overflow: hidden;
        border-color: inherit;

        .circle {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 200%;
          height: 100%;
          border-color: inherit;
          border-style: solid;
          border-width: 2px;
          border-bottom-color: transparent !important;
          border-radius: 50%;
          animation: none;
        }

        &.left .circle {
          left: 0;
          border-right-color: transparent !important;
          transform: rotate(129deg);
          animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
        }

        &.right .circle {
          left: -100%;
          border-left-color: transparent !important;
          transform: rotate(-129deg);
          animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
        }
      }

      .gap-patch {
        position: absolute;
        top: 0;
        left: 45%;
        width: 10%;
        height: 100%;
        overflow: hidden;
        border-color: inherit;

        .circle {
          left: -450%;
          width: 1000%;
        }
      }
    }
  }

  .message {
    padding: 0;
    font-size: 0.875rem;
  }

  &-throbber {
    margin: $space 0;

    .throbber {
      margin-right: $space-one-fifth;
    }

    .message {
      display: inline;
    }
  }

  &-fullscreen {
    position: fixed;
    z-index: 1900;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    opacity: 1;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.7);

    .throbber {
      width: 72px;
      height: 72px;

      .spinner-layer .circle-clipper .circle {
        border-width: 10px;
      }
    }

    .message {
      margin-top: $space-double;
    }
  }
}

@keyframes container-rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }

  25% {
    transform: rotate(270deg);
  }

  37.5% {
    transform: rotate(405deg);
  }

  50% {
    transform: rotate(540deg);
  }

  62.5% {
    transform: rotate(675deg);
  }

  75% {
    transform: rotate(810deg);
  }

  87.5% {
    transform: rotate(945deg);
  }

  to {
    transform: rotate(1080deg);
  }
}

@keyframes right-spin {
  from {
    transform: rotate(-130deg);
  }

  50% {
    transform: rotate(5deg);
  }

  to {
    transform: rotate(-130deg);
  }
}

@keyframes left-spin {
  from {
    transform: rotate(130deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  to {
    transform: rotate(130deg);
  }
}
