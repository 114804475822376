.state {
  display: inline-block;
  padding: 0.3*$space $space;
  border-radius: 3px;
  color: $white;
  font-size: rem-calc(14);
  font-weight: $global-weight-bold;
  line-height: 1.43;
  background: $secondary-color;
  text-transform: uppercase;

  &--green,
  &--success {
    background: $success-color;
  }

  &--red,
  &--alert {
    background: $alert-color;
  }

  &--orange,
  &--warning {
    background: $warning-color;
  }
}
