// Responsive Video using CSS only
.video {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%; // 56.25% = 16x9
  position: relative;
  z-index: 5;

  @media print {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background-color: $dark-white;
    }

    // Play symbol
    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 24px 0 24px 52px;
      border-color: transparent transparent transparent $medium-gray;
    }
  }

  > * {
    @media print {
      display: none;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  
}

.section-video {
  &--wrapper {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden;

    iframe {
      height: 100%;
      position: absolute;
    }
  }

  &--poster {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &--btn {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 3;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: inline-block;
      height: auto;
      width: 10rem;

      
    }

    &:hover {
      background-color: rgba($color: $white, $alpha: 0.3);
      svg {

        filter: drop-shadow(2px 2px 2px $medium-gray);
      }
    }

/*     &:before {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      content: '';
      display: inline-block;
      background: url('../../../img/icons/src/play.svg') no-repeat 0 0;
      height: 3rem;
      width: 4rem;
    } */
  }
}
