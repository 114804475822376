.view {
  &.view-job-search {
    &.overview {
      background: $white;
    }


    .view-filters {
      padding: $space-decuple 0;
      background: $dark-white;

      .heading {
        margin-bottom: $space;
      }

      form {
        @include breakpoint(medium) {
          .form--inline {
            > .form-item {
              @include xy-cell(6);
            }
          }
        }
        fieldset {
          padding: 0;
          border: 0;
          margin: 0;
          width: 100%;
          legend {
            display: none;
          }
          & > div {
            display: flex;
            flex-flow: row wrap;
            margin-top: -15px;
          }
          .form-item {
            width: 100%;
            @include breakpoint(medium) {
              @include xy-cell(6);
            }
          }
        }
      }
    }

    .view__pager {

      &.view-header {
        margin-top: $space-quadruple;
        font-family: $header-font-family;
        font-size: 1.25rem;
        font-weight: $global-weight-bold;
      }
  
      &.view-footer {
        margin-bottom: $space;
      }
    }
    

    table {
      font-size: 1rem;

      th,
      td {
        // View-specific column widths
        // "Job Function"
        &:nth-last-child(1) {
          width: 20%;
        }
        // "Location"
        &:nth-last-child(2) {
          width: 14.5%;
        }
        // "Country"
        &:nth-last-child(3) {
          width: 15.5%;
        }
      }

      td {
        height: rem-calc(80);

        a {
          padding: 0;
          color: $body-font-color;
          font-weight: $global-weight-normal;
          background: none;

          &:hover {
            color: $body-font-color;
          }
        }
      }
    }

    .form-actions {
      margin-top: $space-double;

      @include breakpoint(small only) {
        margin-top: $space-quadruple;
        text-align: center;

        .form-submit {
          width: 100%;
        }

        .reset {
          margin-top: $space-triple;
        }
      }

      @include breakpoint(medium) {
        .reset {
          margin-left: $space-double;
        }
      }
    }
  }
}
