@import 'swiper';

/* anchor links menu */

.anchor-links {
  position: sticky;
  z-index: 10;
  transition: .3s top;
  top: 0;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @include breakpoint(large) {
    box-shadow: none;
  }

  &.is-sticky {
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2);
  }

  &__container {
    background: white;
    display: flex;
    align-items: center
  }

  &__list-items {
    position: relative;

    .swiper-button {
      color: transparent;
      cursor: pointer;
      position: absolute;
      top: 0;
      height: 100%;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;

      &:after {
        content: "";
        position: absolute;
        border-radius: 0px;
        display: block;
        width: 12px;
        height: 12px;
        border-style: solid;
        border-width: 0 0 3px 3px;
        border-color: #003b68;
      }

      &__prev {
        left: -20px;

        @include breakpoint(large) {
          left: -40px;
        }

        &:after {
          transform: rotateZ(45deg);
        }
      }

      &__next {
        right: -20px;

        @include breakpoint(large) {
          right: -40px;
        }

        &:after {
          transform: rotateZ(225deg);
        }
      }
    }

    .swiper-container {
      position: inherit;
      margin: 0 -20px;
      padding: 0 20px;
      overflow: hidden;

      @include breakpoint(large) {
        margin: 0 -30px;
        padding: 0 30px;
      }

      &:before,
      &:after {
        z-index: 2;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 20px;
        background-color: #000;
        background-color: rgba(255, 255, 255, 0);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e73827', endColorstr='#ffffff', GradientType=1);

        @include breakpoint(large) {
          width: 30px;
        }
      }

      &:before {
        left: 0;
        background: linear-gradient(to left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
      }

      &:after {
        right: 0;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 100%);
      }



    }

    .c-link {
      padding-bottom: 7px;
    }

    .swiper-wrapper {
      transition-timing-function: linear;
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      transition: all .3s ease
    }

    .swiper-slide {
      width: auto;
      margin-right: 25px;
      padding: 13px 0;
      cursor: pointer;

      @include breakpoint(large) {
        padding: 24px 0;
      }

    }

  }

  &__list-items__active-line {
    position: absolute;
    bottom: 10px;
    left: 0;
    height: 2px;
    background: $primary-color;

    @include breakpoint(large) {
      bottom: 21px;
    }
  }

}


.section-box {
  /*  top: -80px;
  @include breakpoint(large) {
    top: -100px;
  } */

  .anchor-id {
    position: relative;
    left: 0;
    /* top: -120px; */

    @include breakpoint(large) {
      /* top: -140px; */
    }
  }
}