.sticky-sidebar {
  $section-background-color: $white;
  $section-border-color: $secondary-color;
  $section-toggle-size: 50px;
  $section-toggle-margin: 2px;
  $section-toggle-background: $section-border-color;
  $section-toggle-open-background: $section-background-color;
  $section-toggle-no-toggle-background: $section-background-color;
  $section-toggle-no-toggle-color: $section-border-color;
  $section-link-margin: 7px;
  $section-link-font-weight: 700;

  position: fixed;
  z-index: 11;

  .section,
  .paragraph {
    background: $section-background-color;
    border: 1px solid $section-border-color;
    box-shadow: $prototype-box-shadow;
    display: none;
    margin: 0;
    padding: 15px 20px;
    width: 260px;
    position: absolute;

    &.open {
      display: block;
    }
  }

  .section-toggle {
    background: $section-toggle-background;
    border: 1px solid $section-border-color;
    cursor: pointer;
    text-align: center;
    height: $section-toggle-size;
    line-height: $section-toggle-size;
    width: $section-toggle-size;
    position: relative;
    z-index: 2;

    img {
      display: inline-block;
    }

    &.open {
      &::before {
        background: $section-toggle-open-background;
        border-bottom: 1px solid $section-border-color;
        border-top: 1px solid $section-border-color;
        content: '';
        height: $section-toggle-size;
        width: 2 * $section-toggle-margin;
        position: absolute;
        left: -2 * $section-toggle-margin;
        top: -1px;
      }

      &::after {
        background: $section-toggle-open-background;
        color: $section-border-color;
        content: '×';
        font-size: 2.3rem;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &.no-toggle {
      background: #fff;
    }

    &.back-to-top__arrow {
      border-radius: 0;
      box-shadow: none !important;
      margin: 0;
      vertical-align: initial;
     
    }

  }
  
  .back-to-top:hover .back-to-top {
    &__arrow {
      background-image: url('data:image/svg+xml, <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"> <rect fill="%23003b68" width="2" height="17.09" x="23.9" y="19.95"/> <path fill="%23003b68" d="M 34.47,17.79 14.47,17.84 24.93,-0.37 Z" transform="matrix(0.53,0,0,0.38,11.85,13.19)" /></svg>') !important;
    }
    background-color: $section-toggle-background;
  }

  .field--type-link {
    > div:not(:last-child) {
      margin-bottom: $section-link-margin;
    }

    a,
    .fa {
      display: inline-flex;
      align-items: center;
      font-family: $body-font-family !important;
      font-weight: 700 !important;
      line-height: 1.2;
      padding: 0;

      &::before {
        font-family: "Shareicons" !important;
        font-weight: normal !important;
        font-size: 1.2em;
        margin-right: 0.5rem;
        min-width: 1em;
        text-align: center;
      }

      &::after {
        content: '›';
        font-family: $body-font-family;
        font-size: 1.5em;
        font-weight: normal;
        margin-left: 0.5rem;
      }
    }
  }

  &--position--right {
    right: 0;
    top: calc(50% - 2 * (#{$section-toggle-size} + #{$section-toggle-margin}) - 0.5 * #{$section-toggle-margin});

    .section,
    .paragraph {
      min-height: 4 * ($section-toggle-size + $section-toggle-margin) - $section-toggle-margin;
      right: $section-toggle-size + $section-toggle-margin;
      top: 0;
    }

    .paragraph.fire {
      min-height: 3 * ($section-toggle-size + $section-toggle-margin) - $section-toggle-margin;
    }

    .section-toggle {
      margin-left: auto;
      margin-bottom: $section-toggle-margin;
    }
  }
  &--position--right.fire { 
    .paragraph {
      min-height: 3 * ($section-toggle-size + $section-toggle-margin) - $section-toggle-margin;
    } 
  }
}
