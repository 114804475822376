
.social-media-links {
  position: relative;
  text-align: center;

  &__text {
    margin: 0 0 $space-double 0;
    text-transform: uppercase;
    color: $primary-color;
    font-weight: $global-weight-bold;
    letter-spacing: 4.25px;
  }

  @include breakpoint(medium) {
    display: flex;
    align-items: center;
    text-align: left;

    &__text {
      margin: 0;
    }
  }
}

.social-media-links--platforms,
.shariff ul {
  margin: 0;
  list-style: none;
  font-size: 0;
  background: $body-background;

  @include breakpoint(medium) {
    padding-left: 0.5 * $space;
  }

  li {
    display: inline-block;

    a {
      display: block;
      width: $space-quintuple;
      height: $space-quintuple;
      margin: $space;
      color: $light-gray;
      border: 0;
      background: $dark-white;
      line-height: $space-quintuple;
      text-align: center;
      transition:
        background-color 0.25s ease-out,
        color 0.25s ease-out,
        transform 0.25s ease-out,
        box-shadow 0.25s ease-out;

      &::before {
        display: none;
      }

      &:hover {
        color: $white;
        background: $primary-color;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        transform: translateY(-#{rem-calc(4)});

        &::before {
          background: $primary-color;
        }

        span,
        .fa {
          color: $white;
        }

      }

      @include breakpoint(medium only) {
        margin: $space 0.5 * $space;
      }

      @include breakpoint(medium) {
        &::before {
          content: '';
          position: absolute;
          right: 0;
          bottom: $space;
          left: 0;
          height: 2px;
          z-index: -1;
          transition: background-color 0.25s ease-out;
        }
      }
    }

    .fa,
    .fab,
    .fas {
      vertical-align: middle;
      font-size: 2rem;
      color: $secondary-color;

      &-xing {
        font-size: 2.4rem;
      }

      &-twitter {
        font-size: 2.2rem;
      }
    }
  }
}

.shariff ul {
  li {
    a {
      width: $space-quadruple;
      height: $space-quadruple;
      margin: $space 0.75 * $space;
      line-height: $space-quadruple;
    }

    .fa,
    .fab,
    .fas {
      font-size: 1.8rem;

      &-facebook,
      &-facebook-f {
        font-size: 1.6rem;
      }
    }
  }
}
