// GENERAL LAYOUT STYLES
// (Global layout styles that can be re-used)
.section {
  margin: $space-decuple 0;
}

// Primary section spacing using padding.
.section-box {
  padding-top: rem-calc(40) !important;
  padding-bottom: rem-calc(40) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  & + .paragraph {
    margin-top: 0;
  }

  .in-iframe & {
    padding-top: 0 !important;
  }

}

.section-box--protruding {
  margin-bottom: rem-calc(40) !important;
}

.section--force {
  margin: $space-decuple 0 !important;
}

.width-full {
  width: 100%;
}

//  HELPER STYLES
//  (Global helper styles that can be re-used)
//  |
//  `-- Typography
//     (text styling properties like font-sizes, font-weights, etc.)
.size-md {
  font-size: rem-calc(30);
}

.type-heavy {
  font-weight: 700;
}

//  |
//  `-- Content reset
//     (Reset vertical spacing of the nested elementslike paragraphs for example.)
.reset-spacing {
  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

//  |
//  `-- Margin overrides (ml - margin-left, ...)
.ml-0x {
  margin-left: 0 !important;
}

.mb-0x {
  margin-bottom: 0 !important;
}

.mt-0x {
  margin-top: 0 !important;
}

.mb-1x {
  margin-bottom: $space !important;
}

.mb-2x {
  margin-bottom: $space * 2;
}

.mb-4x {
  margin-bottom: $space * 4;
}

.mb-5x {
  margin-bottom: $space * 5;
}

//  |
//  `-- Padding overrides (pl - padding-left, ...)
.pt-0x {
  padding-top: 0 !important;
}
.pt-2x {
  padding-top: $space-double !important;
}
.pt-4x {
  padding-top: $space-quadruple !important;
}
.pt-6x {
  padding-top: $space-sextuple !important;
}
.pt-7x {
  padding-top: $space-septuple !important;
}
.pt-8x {
  padding-top: $space-octuple !important;
}
.pt-10x {
  padding-top: $space-decuple !important;
}

.pb-0x {
  padding-bottom: 0 !important;
}

.pb-2x {
  padding-bottom: $space-double !important;
}
.pb-4x {
  padding-bottom: $space-quadruple !important;
}
.pb-6x {
  padding-bottom: $space-sextuple !important;
}
.pb-7x {
  padding-bottom: $space-septuple !important;
}
.pb-8x {
  padding-bottom: $space-octuple !important;
}
.pb-10x {
  padding-bottom: $space-decuple !important;
}


//  |
//  `-- Backround color
.bg-white {
  background-color: #fff !important;
}

// Text alignment
// |
// `-- Text center
.text-center {
  text-align: center;
}

// Display
.flex-box {
  display: flex;
}

// Backgrounds
.background-gray {
  background: $dark-white;
}

.background-blue {
  background: $primary-color;
}

.background-red {
  background: $yt-color; 
}