@import "mixins";

%background-cover-like {
  position: absolute;
  top: -9999px;
  right: -9999px;
  bottom: -9999px;
  left: -9999px;
  max-width: none;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  margin: auto;

  @media print {
    position: static;
    margin-bottom: $space * 4;
  }
}

%b1 {
  font-size: 1.25rem;
  line-height: 1.5;
}

%block-title {
  font-size: 1.4rem;
  line-height: 1.1667;
  font-family: $header-font-family;
  color: $black;
  font-weight: 700;
}

%form-checkbox {
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    opacity: 0;

    & + label {
      position: relative;
      padding: 0 0 0 2.5*$space;
      cursor: pointer;
      line-height: $space-double;

      &::before {
        @include checkbox();

        content: '';
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &:checked + label::after {
      @include tick();

      content: '';
      position: absolute;
      top: 2px;
      left: 6px;
    }
  }
}

// input--with-icon--right input--with-icon--magnifier
%input--with-icon {
  background-repeat: no-repeat;

  &--right {
    background-position: calc(100% - 20px) 50%;
  }

  &--magnifier {
    padding-right: 50px;
    background-image: url("data:image/svg+xml,%3Csvg class='input-symbol__symbol' width='25' height='25' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke-width='.1' fill-rule='nonzero' fill='%23A3A3A3' stroke='%23A3A3A3'%3E%3Cpath d='M10.617 9.725a.472.472 0 110 .943.472.472 0 010-.943z'/%3E%3Cpath d='M1.644 21.689l6.294-6.294a9.347 9.347 0 01-1.566-5.199c0-5.2 4.231-9.431 9.433-9.431 5.199 0 9.43 4.23 9.43 9.431 0 5.2-4.23 9.432-9.431 9.432a9.343 9.343 0 01-5.199-1.566l-1.74 1.74s0 0 0 0l-4.554 4.554c-.356.357-.83.553-1.334.553-.503 0-.977-.196-1.333-.552a1.873 1.873 0 01-.553-1.334c0-.504.196-.978.553-1.334zm9.183-4.62s0 0 0 0a8.415 8.415 0 004.977 1.615c4.68 0 8.488-3.807 8.488-8.488 0-4.68-3.808-8.488-8.488-8.488s-8.488 3.808-8.488 8.488c0 1.8.558 3.521 1.616 4.977a8.53 8.53 0 001.895 1.895zm-.99.427a9.603 9.603 0 01-1.333-1.334L7.166 17.5c.407.48.854.927 1.334 1.334l1.338-1.338zM2.31 23.69a.937.937 0 00.667.276.937.937 0 00.667-.276l4.187-4.188a12.52 12.52 0 01-1.333-1.333L2.31 22.356a.936.936 0 00-.276.667.936.936 0 00.276.667z'/%3E%3Cpath d='M15.804 2.651c4.16 0 7.545 3.385 7.545 7.545s-3.385 7.545-7.545 7.545-7.545-3.384-7.545-7.545c0-4.16 3.384-7.545 7.545-7.545zm0 14.147a6.61 6.61 0 006.601-6.602 6.61 6.61 0 00-6.601-6.601 6.61 6.61 0 00-6.602 6.602 6.61 6.61 0 006.602 6.602z'/%3E%3Cpath d='M10.519 8.172a5.659 5.659 0 012.045-2.613 5.619 5.619 0 013.24-1.021.472.472 0 010 .943 4.748 4.748 0 00-4.404 3.03.472.472 0 11-.88-.339z'/%3E%3C/g%3E%3C/svg%3E");
  }
}

%form--inline {
  .form-item,
  & > .form-item {
    float: none;
  }

  @include breakpoint(small only) {
    .form-actions {
      text-align: center;
    }
  }

  @include breakpoint(medium) {
    @include xy-grid();
    @include xy-gutters($negative: true);

    & > .form-item,
    & > .form-actions {
      @include xy-cell(12);
    }

    & > .form-type-select {
      @include xy-cell(6);
    }
  }

  .select2-container {
    width: 100%;
  }
}

%card-hover {
  transition: all 0.2s;

  &:hover {
    transform: translateY(-#{rem-calc(10)});
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
}

%visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}
