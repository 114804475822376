.view.view-file-search {
  .view-filters {

    .heading {
      @extend %block-title;
    }

    form {
      padding-bottom: $space-quintuple;
      border-bottom: 2px solid $border-color;

      @include breakpoint(medium) {
        .form--inline {
          & > .form-type-select {
            @include xy-cell(6);
          }
        }

        .category-wrapper {
          @include xy-cell(6);
        }
      }
    }
  }

  .view-content form table .form-item .form-checkbox {
    width: 13px;
    height: 13px;
    position: unset !important;
  }

  .view__pager.view-header {
    margin-top: $space-quintuple;
  }

  .views-field-file-size-rendered {
    white-space: nowrap;
  }
}
