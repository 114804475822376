$bevelled-height: 25vw; // paragraphs height

.paragraph-teaser-image {
  &__heading {
    margin-bottom: $space-triple;
  }

  &__body {
    @extend %b1;

    margin-bottom: $space-triple;
  }

  &__cta,
  .cta-link {

    @include button();
  }
  /* youtube cta position fix */
  &.paragraph-bevelled--right {
    .cta-link.cta-yt {
      float: right;
    }
  }

  @include breakpoint(small only) {
    text-align: center;
  }

  &--no-image &__content {
    @include breakpoint(large) {
      width: 40%;
      margin-left: calc(0.5 * (100% - #{$global-width-medium}));
    }

    @include breakpoint(xlarge) {
      width: 34%;
      margin-left: calc(0.5 * (100% - #{$global-width}));
    }

    @media print {
      width: auto;
      margin-left: 0;
    }
  }

  &--no-image &__cta,
  &--no-image .cta-link {
    @include button--ghost();
  }

  &::after {
    content: '';
    display: block;
    height: $bevelled-height !important;
    font-size: 0;

    @media print {
      display: none;
    }
  }
}



.paragraph-teaser-image2 {

  position: relative;
  display: flex;
  align-items: center;
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;

  &.section-box {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__media {
    width: 43.75%;
    overflow: hidden;
    white-space: nowrap;

    img {
      width: 100%;
      opacity: 0.6;
      mix-blend-mode: multiply;
    }

    @include breakpoint(small only) {
      display: none;
    }
  }

  &__content {

    margin-left: $space;
    margin-right: $space;


    &__heading {
      margin-bottom: $space-triple;
    }

    &__body {
      @extend %b1;

      margin-bottom: $space-triple;
    }

    &__cta,
    .cta-link {      

      @include button();
    }

    @include breakpoint(large) {
      margin-left: $space-double;
      margin-right: $space-double;
    }

    @include breakpoint(xlarge) {
      margin-left: $space-septuple;
      margin-right: $space-septuple;
    }
  }

  @include breakpoint(small only) {
    text-align: center;
  }

  &--no-image &__content {
    @include breakpoint(large) {
      width: 40%;
      margin-left: calc(0.5 * (100% - #{$global-width-medium}));
    }

    @include breakpoint(xlarge) {
      width: 34%;
      margin-left: calc(0.5 * (100% - #{$global-width}));
    }

    @media print {
      width: auto;
      margin-left: 0;
    }
  }

  &--no-image &__cta,
  &--no-image .cta-link {
    @include button--ghost();
  }

  /* youtube cta position fix */
  &.paragraph-bevelled--right {
    .cta-link.cta-yt {
      float: right;
    }
  }

}