.webform-type-fieldset {
  margin: $space-triple 0;
  padding: 0;
  border: 0;
}

.fieldgroup {
  padding: 0;
  border: 0;
}

.fieldset--inline {
  display: flex;
}

.fieldset__main {
  display: flex;
}

.fieldset__main--fluid {
  >* {
    width: 100%;
  }
}

.legend--primary {
  margin-bottom: calc(48rem/16);
}

.form-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.form-footer-item {
  margin-top: rem-calc(30);
  margin-left: rem-calc(10);
  margin-right: rem-calc(10);
}

.form-footer__fields {
  margin-bottom: calc(60rem/16);
}

.legend {
  margin-bottom: calc(46rem/16);

  // Reset bottom margins for last child element
  >* {
    &:last-child {
      margin-bottom: 0;
    }
  }
}


.paragraph-webform {


  .two-columns {
    @include xy-grid-container;

    >legend {
      @include xy-grid;
      @include xy-gutters($negative: true);

      >* {
        @include xy-cell();

        // Some sort of foundation bug where the
        // margins get messed up.
        @include breakpoint(medium) {
          @include xy-cell();
        }
      }
    }

    >div {
      @include xy-grid;
      @include xy-gutters($negative: true);

      .form-item {
        @include xy-cell();

        margin-top: 0;
        margin-bottom: 0;

        @include breakpoint(medium) {
          @include xy-cell(6);

          margin-top: 0;
          margin-bottom: 0;

          &.break-after {
            margin-right: 50%;
          }
        }
      }
    }
  }


  .three-columns {
    @include xy-grid-container;

    >legend {
      @include xy-grid;
      @include xy-gutters($negative: true);

      >* {
        @include xy-cell();

        // Some sort of foundation bug where the
        // margins get messed up.
        @include breakpoint(medium) {
          @include xy-cell();
        }
      }
    }

    >div {
      @include xy-grid;
      @include xy-gutters($negative: true);

      .form-item {
        @include xy-cell();

        margin-top: 0;
        margin-bottom: 0;

        @include breakpoint(medium) {
          @include xy-cell(4);

          margin-top: 0;
          margin-bottom: 0;

          &.break-after {
            margin-right: 33.3%;
          }
        }
      }
    }
  }

  // Horizontal rule
  .webform-horizontal-rule {
    width: 100%;
    border-color: $border-color;
    border-width: 2px;
    // Override
    margin-top: rem-calc(30) !important;
    margin-bottom: rem-calc(54) !important;
  }

  .webform-horizontal-rule--collapse {
    margin-bottom: rem-calc(15) !important;
  }

  .webform-submission-form {
    .webform-link--wrapper {
      .form-item {
        width: 100%;
        margin: 0;
      }
    }

    div[id*=-ajax-wrapper],
    .js-form-wrapper {
      width: 100%;
    }

    .address--wrapper {
      margin: 0;

      .address-container-inline {
        display: none;
      }

      &>div {
        &>div {
          box-sizing: border-box;
        }

        @include breakpoint(medium) {
          @include xy-grid;

          width: 100%;

          &>div {
            @include xy-cell(6);

            .form-item {
              width: 100%;
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }

      .form-item {
        position: relative;

        .ajax-progress-throbber {
          position: absolute;
          left: $space-double;
          top: 100%;
        }
      }
    }

    .form-actions {
      margin-top: $space-quadruple;

      @include breakpoint(small only) {
        .form-submit {
          width: 100%;
        }
      }
    }

    .shs-container {
      margin-top: -1.5*$space;
      margin-bottom: -1.5*$space;
    }

    .shs-field-container {
      margin-bottom: 0;

      .shs-widget-container {
        margin-top: 1.5*$space;
        margin-bottom: 1.5*$space;
      }

      @include breakpoint(medium) {
        @include xy-grid;
        @include xy-gutters($negative: true);

        .shs-widget-container {
          @include xy-cell(6);
        }
      }
    }

    .help-required-markup {
      margin-bottom: $space-quintuple;
      font-size: $cite-font-size;
    }


    .return-items-actions {

      margin-top: 0;
      display: flex;
      flex-direction: row;
      width: auto !important;
      padding: $space 0 0 0;
  
      .button {
        @include button;
        @include xy-gutters($gutter-position: left);
  
        min-width: auto !important;
        width: rem-calc(60);
        margin-right: 0;
        opacity: 1;
        outline: none;
      }
    }

  }


  

  .webform-options-display-side-by-side {
    display: flex;
    margin-top: 2.5*$space;
    margin-left: -(rem-calc(14));
    margin-right: -(rem-calc(14));
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    >.form-item {
      margin-left: rem-calc(14);
      margin-right: rem-calc(14);
    }

    @include breakpoint(small only) {
      >.form-item {
        margin-top: $space-one-half;
        margin-bottom: $space-one-half;
      }
    }
  }

  .webform-checkboxes {
    margin-top: $space-triple;
  }

  .webform-options-display-one-column {
    margin-top: 2.5*$space;

    >.form-item {
      margin-top: $space;
      margin-bottom: $space;

      &:last-child {
        @extend .fieldset__main;
        @extend .fieldset__main--fluid;
      }
    }
  }

  .pick-up-address {
    margin: $space-triple 0 $space-quintuple;

    summary {
      color: $primary-color;
      font-weight: $global-weight-bold;
      text-align: center;
      outline: 0;
      cursor: pointer;

      &::-webkit-details-marker {
        display: none;
      }
    }

    .address--wrapper>div {
      @include xy-gutters($negative: true);

      width: auto;
    }
  }

  .webform-submission-newsletter-signup-form {
    .shs-field-container {
      .shs-widget-container:not(:first-child) {
        margin-top: $space-triple;
      }

      @include breakpoint(medium) {
        display: block;
        margin-left: 0;
        margin-right: 0;

        .shs-widget-container {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    .form-actions {
      text-align: center;
    }
  }

  .form-type-webform-likert {
    margin: $space-quadruple 0;

    &>label {
      padding-bottom: $space;
      font-size: 1rem;
      line-height: 1.25em;
      opacity: 1;
    }

    .webform-likert-table-wrapper {
      overflow: auto;
    }

    .webform-likert-table {
      min-width: auto;
      width: 100%;
    }

    .form-item {
      label {
        opacity: 1;
      }
    }

    @media (max-width: 768px) {
      .form-item {
        label {
          font-size: 1rem;
          line-height: 1.25em;
        }
      }

      .form-type-item {
        margin-top: $space-double;
      }

      .form-type-radio,
      .form-type-checkbox {
        padding: 0 $space-double;
      }
    }

    @media (min-width: 769px) {

      .webform-likert-table,
      .sticky-header {
        th {
          font-size: 1rem;
        }

        th,
        td {
          padding: $space $space-double;
        }
      }

      .form-item {
        margin: 0;

        label {
          padding: 0;
        }

        .form-item__description {
          padding: 0;
        }
      }

      .form-type-radio,
      .form-type-checkbox {
        label {
          display: block;
          width: calc(36rem/16);
          height: calc(36rem/16);
          margin: $space-double auto 0;
        }
      }
    }
  }

  .webform-required {
    margin-top: $space;
    font-weight: $global-weight-bold;
    color: $medium-gray;

    .form-required {
      margin-right: $space-one-half;
      font-size: 1.25rem;
    }
  }

}

#form-ebl {
  h2 {

    font-size: 1.25rem;
      line-height: 1.4;
      color: $body-font-color;
    
      @include breakpoint(medium) {
        font-size: 1.5rem;
        line-height: 1.47;
      }
  }
}