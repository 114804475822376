@import 'dataTables.foundation';

.paragraph-download-area {
  &__content.paragraph-bevelled__content {
    @include breakpoint(($global-width-medium + rem-calc($space-quadruple)) down) {
      width: 100%;
      margin: 0;
      padding: 0 $space-double;
    }
  }

  .tablefield-wrapper .table-wrapper {
    /* overflow-x: auto; */
    overflow: visible;
  }

  .view .view-content .table-wrapper {
    overflow-x: auto;

    table {
      font-size: rem-calc(14);

      th,
      td {
        text-align: left;
      }

      th {
        padding-left: 10px;
        font-size: rem-calc(18);
        cursor: pointer;

        &:first-child {
          padding-left: 20px;
        }
      }

      td {
        padding: 15px 20px 15px 10px;
        vertical-align: top;
        line-height: rem-calc(24);

        &:first-child {
          width: 90%;
          padding-bottom: 9px;
          padding-left: 20px;
          line-height: normal;
        }
      }

      .file-link {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
      }

      .file-ext {
        display: block;
        width: 20px;
        height: 24px;
        margin-right: $space-double;
      }

      .time {
        font-size: inherit;
        letter-spacing: normal;
      }
    }
  }


  
}


/* protected downloads modal  */
.in-iframe {
  overflow-x: hidden;
}

@import '../datatables/table-js-sort';
@import '../datatables/tablefield';
