iframe {
  display: block;
  margin: 0 auto;
  border: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}

.iframe {
  &__title {
    text-align: center;
  }
}
