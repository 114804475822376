.all-search-header {
  @include custom-xy-grid-container();

  width: 100%;

  &__text {
    font-size: 1.25rem;
  }

  .theme-tabs {
    margin: $space-quadruple 0 $space-quintuple 0;

    a {
      padding-left: $space-triple;
      padding-right: $space-triple;
    }
  }
}
