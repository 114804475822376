.with-lightbox {

    .lightbox-open {
        position: absolute;
        top: 0;
        left: 0;
        width: 44px;
        height: 44px;
        background: $dark-white;
        overflow: hidden;
        z-index: 6;
        outline: 0;

        svg {
            vertical-align: middle;
        }

        .plus,
        .plus-hover {
            width: 24px;
            height: 24px;
        }

        .plus-hover {
            display: none;
        }

        &:hover {
            .plus {
                display: none;
            }

            .plus-hover {
                display: inline;
            }
        }
    }
}

.lightbox {
    @include custom-xy-grid-container();

    position: relative;
    padding: $space-sextuple 0 $space-triple;
    background: $white;
    outline: 0;

    &__content {
        height: 100%;

        &.slick-slider {
            margin: 0;

            .slick-arrow {
                box-shadow: none;
                background: transparent;
            
                &:hover,:active {
                  &::after {
                    border-color: $primary-color;  
                  }
                }
              }
        }
    }

    @include breakpoint(small only) {
        margin: 0;
    }

    .video {
        min-width: calc(100vw - 60px);

        @media (min-width: 640px) {
            min-width: calc(100vw - 100px);
        }

        @media (min-width: 1000px) {
            min-width: 900px;
        }

        @media (min-width: 1440px) {
            min-width: 1160px;
        }

    }

    .icon-close {
        position: absolute;
        top: $space-double;
        right: $space-double;
        outline: 0;
    }

    &#protected-downloads-lightbox {
        max-width: $grid-container-medium;
        iframe {
            width: 100%;
            height: 100%;
            box-shadow: none;
        }
        .layout-content::before {
            height: 0;
        }
    }
    .paragraph-webform {
        margin: 0 $space-double;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

    }


}