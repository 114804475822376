.language-switcher {
  font-size: $medium-font-size;

  .form-item {
    margin: 0;
  }

  label.visually-hidden {
    padding: 0;
  }

  @include breakpoint(medium down) {
    display: flex;
    align-items: center;

    .form-item {
      flex-grow: 0;
    }
  }

  &__title {
    font-weight: $global-weight-bold;

    @include breakpoint(large) {
      display: none;
    }
  }

  .form-select {
    opacity: 0;
  }

  .select2-container {
    display: block;
    max-width: 100%;
    width: 55px !important;


    .select2-selection--single {
      height: auto;
      padding: $space 2.5 * $space ($space - 2px) 0.5 * $space;
      border: 2px solid transparent;
      border-bottom: 2px solid transparent;
      line-height: 1.25rem;
      font-size: inherit;

      .select2-selection__rendered {
        color: $dark-gray;
        line-height: 1.25rem;
        text-transform: uppercase;
        border: 0;

        @include breakpoint(large) {
          @include link-hover-primary(currentColor);

          padding: 0 0 (6rem/16);
        }
      }

      .select2-selection__arrow {
        top: 1.2 * $space;
        right: 0.7*$space;
        width: $space-double;
        height: $space-double;
        margin: 0;
        border-left: 0;

        b {

          position: absolute;
          top: -3px;
          left: 6px;
          right: 0;
          bottom: 0;
          width: 0.7*$space;
          height: 0.7*$space;
          border-width: 0 0 2px 2px;
          border-color: $black;
          border-radius: 1px;
          transform: rotateZ(-45deg);
          transition: .5s transform;
          margin: auto;
        }
      }
    }

    &.select2-container--open .select2-selection--single {
      border-color: $dark-white;

      .select2-selection__arrow b {
        top: 3px;
        border-width: 0 0 2px 2px;
        border-color: $black;
        transform: rotateZ(-225deg);
      }
    }

    .select2-selection {
      box-shadow: none;
      font-weight: 700;
      // On hover
      &:hover {
        box-shadow: none;
      }
    }
  }

  .select2-dropdown {
    border: 2px solid $dark-white;
    border-top: 0;
    color: $medium-gray;
    text-align: left;
    background: $body-background;
    box-shadow: none;

    @include breakpoint(medium down) {
      border-radius: 0;
    }
  }

  .select2-results__option {
    padding: $space-one-half $space;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: $global-weight-normal;

    @include breakpoint(large) {
      padding: $space 1.5*$space;
    }
  }

  .select2-container--default {
    .select2-results__option--highlighted[aria-selected] {
      color: $medium-gray;
      background-color: $dark-white;
    }

    .select2-results__option[aria-selected=true] {
      color: $dark-gray;
      background-color: $dark-white;
    }
  }
}
