@import 'jquery-ui'; // loaded from node_modules/jquery-ui/dist/themes/base

.ui-widget.ui-autocomplete {
  &.ui-widget-content {
    border: 2px solid $border-color;
    border-top: 0;
    border-bottom-right-radius: $input-radius;
    border-bottom-left-radius: $input-radius;
    padding: $space-double 2.5*$space;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    z-index: 1010;

    &::before {
      content: '';
      position: absolute;
      top: -$input-radius;
      right: -2px;
      left: -2px;
      height: $input-radius;
      border: 2px solid $border-color;
      border-width: 0 2px;
      background: $white;
    }

    &::after {
      content: '';
      position: absolute;
      top: -$space-one-half;
      right: $space-double;
      left: $space-double;
      height: 1px;
      background: $medium-lighter-gray;
    }

    .ui-menu-item-wrapper {
      padding: 0;
      font-weight: $global-weight-bold;
      line-height: 1.75;
    }

    .ui-state-active {
      margin: 0;
      border: 0;
      color: $primary-color;
      background: none;
    }
  }
}
