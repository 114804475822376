.bcknd {



    *:before,
    *:after {
        content: '';
    }

    hr {
        position: relative;
        overflow: visible;
        border: 0;
        margin: 1.35em auto;
        max-width: 100%;
        box-sizing: border-box;
        /* text-align: center; */
        font-size: 14px;
        width: 100%;
        color: $primary-color;;

        &.type-1 {
            height: 0px;
            border-radius: 0px;
            border: 5px solid $primary-color;

            &::after {
                bottom: -10px;
            }
        }

        &.type-2 {
            height: 8px;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="3px" height="3px" viewBox="0 0 3 3" fill="%23003b68"><polygon points="0,0.5 0,1.5 1.5,3 2.5,3"/><polygon points="2.5,0 1.5,0 3,1.5 3,0.5"/></svg>');
            background-size: 10px;
            
            &::after {
                bottom: -6px;
            }
        }

        &.type-3 {
            height: 8px;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="3px" height="3px" viewBox="0 0 3 3" fill="%23003b68"><polygon points="0,0.5 0,1.5 1.5,3 2.5,3"/><polygon points="2.5,0 1.5,0 3,1.5 3,0.5"/></svg>');
            background-size: 3px 3px;
            
            &::after {
                bottom: -6px;
            }
        }

        &::after {
            position: absolute;
             left: 15%;
            /* transform: translateX(-50%); */
            content: attr(data-content);
            line-height: 1.5em;
            padding: 0 10px;
            background-color: $white;
        }



    }













}