@import '../vendor/animatecss/_base';
@import '../vendor/animatecss/attention_seekers/shake';

.button {
  @include button;

  &--ghost {
    @include button--ghost();
  }

  &--alt {
    @include button--alt();
  }
}

.button--symbol {
  border-radius: 50%;
  padding: 0;
  font-size: rem-calc(32);
  min-height: auto;
  min-width: auto;
  display: inline-block;
  line-height: 0;
  margin: 0;

  &:hover,
  &:focus {
    color: $primary-color;
    background-color: $white;
  }

  &:hover {
    transform: translateY(-#{rem-calc(2)});
  }
}
