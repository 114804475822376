a,
.link {
  @include link;

  &::after {
    @media print {
      display: none;
    }
  }
}

.link--primary {
  color: $black;
  font-weight: 400;

  &:hover {
    color: $black;
  }
}

.link--clean {
  background: none;
}

.cta-yt {
  color: white !important;
  background-color: $yt-color !important;
  display: flex !important;
  border: none !important;
  align-items: center;
  justify-content: start;
  width: fit-content;
  border: none;
  height: 50px;

  .cta-text {
    padding-left: 35px;
    padding-top: 2px;
  }
}

.cta-link {
  display: inline-block;
  min-width: 247px;
  padding: 10px 15px;
  border: 2px solid $primary-color;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  line-height: 1.3;
  background-color: $primary-color;
  -webkit-transition: background-color .25s ease-out,color .25s ease-out,-webkit-transform .25s ease-out,-webkit-box-shadow .25s ease-out;
  transition: background-color .25s ease-out,color .25s ease-out,-webkit-transform .25s ease-out,-webkit-box-shadow .25s ease-out;
  transition: background-color .25s ease-out,color .25s ease-out,transform .25s ease-out,box-shadow .25s ease-out;
  transition: background-color .25s ease-out,color .25s ease-out,transform .25s ease-out,box-shadow .25s ease-out,-webkit-transform .25s ease-out,-webkit-box-shadow .25s ease-out;
  background-image: none!important;

  //@include button();
  @include button($hoverColor: $primary-color);
}

.cta-button-align-center, 
.center-text {

  .cta-yt {
    margin-right: auto;
    margin-left: auto;
  }
}


.cta-button-align-right .cta-yt{
  margin-left: auto;
}
