.paragraph-tabs {
  margin-top: (50rem/16);

  .tabs {
    border-bottom: 0;
    flex-direction: column;
    /* margin-bottom: rem-calc(50); */

    // TODO: Check why the 'breakpoint' mixin isn't wokring
    @media (min-width: map-get($breakpoints, medium)) {
      flex-direction: row;
    }

    li {
      display: flex;
      flex-grow: 1;
      flex-basis: 0;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
      }
    }
  }
}

.paragraph--type--webform {
  margin-top: $space-quintuple;
}


